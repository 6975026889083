import React from "react";
import EditIcon from "../../Common/Svgs/EditIcon";
import { PERMISSIONS } from "../Login/constants";
import usePermission from "../../Core/hooks/usePermission";
import TrashIcon from "../../Common/Svgs/TrashIcon";
import { BatterySecondaryIcon } from "../../Common/Svgs/BatteryIcon";

const useThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SESSION_EDIT]),
    },
    {
      id: 2,
      value: "Associate Batteries",
      icon: (
        <BatterySecondaryIcon
          size={16}
          className="text-dark-gray group-hover:text-black"
        />
      ),
      hasPermission: usePermission([PERMISSIONS.SESSION_EDIT]),
    },
    {
      id: 3,
      value: "Delete Session",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.SESSION_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useThreeDotsOptions;

/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PERMISSIONS } from "../../Login/constants";
import { client } from "../../../Utils/axiosClient";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import TrikePermissions from "./TrikePermissions";
import BatteryPermissions from "./BatteryPermissions";
import TripPermissions from "./TripPermissions";
import UserPermissions from "./UserPermissions";
import LocationPermissions from "./LocationPermissions";
import InventoryPermissions from "./InventoryPermissions";
import ServicePermissions from "./ServicePermissions";
import SettingsPermissions from "./SettingsPermissions";
import ChargerPermissions from "./ChargerPermissions";
import FinancePermissions from "./FinancePermissions";
import {
  getSystemRoles,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";
import MobileAppPermission from "./MobileAppPermission";
import ConfirmRoleModal from "./ConfirmRoleModal";
import { updateHome } from "../../../Redux/Slices/home.slice";
import TillerPermissions from "./TillerPermissions";
import SessionPermissions from "./SessionPermissions";

const CreateRole = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails, currentRole, duplicateRole } = useSelector(
    (state) => state.loggedInUser,
  );

  const { confirmRoleModal } = useSelector((state) => state.home);

  const { control, formState, handleSubmit, setError, watch, clearErrors } =
    useForm({
      defaultValues: {
        name: id && !duplicateRole ? currentRole?.name : "",
      },
    });

  const localName = watch("name");
  const hasError = formState?.errors?.name?.message;

  const [permissions, setPermissions] = useState([
    PERMISSIONS.TRIKE_VIEW,
    PERMISSIONS.BATTERY_VIEW,
    PERMISSIONS.TRIP_VIEW,
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.PARTNER_VIEW,
    PERMISSIONS.LOCATIONS_VIEW,
    PERMISSIONS.INVENTORY_VIEW,
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SETTINGS_GENERAL_VIEW,
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
    PERMISSIONS.CHARGER_VIEW,
    PERMISSIONS.REVENUE_VIEW,
    PERMISSIONS.RATE_VIEW,
    PERMISSIONS.APP_NO_ACCESS,
    PERMISSIONS.TILLER_VIEW,
    PERMISSIONS.SESSION_VIEW,
  ]);

  const [focus, setFocus] = useState(false);

  const onCancel = () => {
    navigate(-1);
  };

  const onCreate = async (e) => {
    const payload = {
      roleName: e.name.toLowerCase().trim(),
      permissions,
      createdById: userDetails.userId,
    };

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: true,
        }),
      );

      if (id) {
        payload.roleId = parseInt(id, 10);

        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/update-system-role`,
          payload,
        );

        if (userDetails.roleId == id) {
          await dispatch(getUserByEmail({ email: userDetails.email }));
        }

        dispatch(
          updateHome({
            key: "confirmRoleModal",
            value: { visible: false, mode: "create" },
          }),
        );

        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              visible: true,
              title: "1 Role Edited",
              description: `${payload.roleName} role was edited successfully.`,
            },
          }),
        );
      } else {
        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/create-system-role`,
          payload,
        );

        dispatch(
          updateHome({
            key: "confirmRoleModal",
            value: { visible: false, mode: "create" },
          }),
        );

        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              visible: true,
              title: "1 Role Created",
              description: `${payload.roleName} role was created successfully.`,
            },
          }),
        );
      }

      await dispatch(getSystemRoles());

      navigate(-1);
    } catch (error) {
      console.log(error);

      setError(error.response.data.type, {
        type: "server",
        message: error.response.data.message,
      });

      dispatch(
        updateHome({
          key: "confirmRoleModal",
          value: { visible: false, mode: "create" },
        }),
      );
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );

      dispatch(
        updateLoggedInUser({
          key: "duplicateRole",
          value: false,
        }),
      );
    }
  };

  const openModalOnCreate = () => {
    if (!localName) {
      setError("name", {
        message: "Required",
      });

      return;
    }

    dispatch(
      updateHome({
        key: "confirmRoleModal",
        value: {
          visible: true,
          mode: id ? "edit" : "create",
        },
      }),
    );
  };

  useEffect(() => {
    if (id || duplicateRole) {
      setPermissions([...currentRole.permissions]);
    }

    return () => {
      dispatch(
        updateLoggedInUser({
          key: "duplicateRole",
          value: false,
        }),
      );
    };
  }, [id, duplicateRole, currentRole, dispatch]);

  useEffect(() => {
    if (localName && hasError) clearErrors("name");
  }, [localName, hasError, clearErrors]);

  console.log(permissions, "check permissionss");

  return (
    <CustomLayout pageName="Create Role">
      <div className="w-full bg-background-white">
        <div className="px-6">
          <div className="flex items-center justify-between py-6">
            <p className="font-poppins text-base font-medium capitalize">
              Create a new user role
            </p>

            <div className="flex items-center gap-x-3">
              <Button
                size="filter"
                width="generateUser"
                colors="grey"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                // onClick={handleSubmit(onCreate)}
                onClick={openModalOnCreate}
              >
                {id ? "Save" : "Create"}
              </Button>
            </div>
          </div>

          <div className="w-full bg-light-grey h-[1px]" />
        </div>
      </div>

      <div className="bg-background-white w-full h-[calc(100vh-88px-72px)] overflow-y-scroll hide-scrollbar">
        <div className="px-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
            Name your role
          </p>

          <CustomInputWithController
            intent="createUser"
            control={control}
            formState={formState}
            focus={focus}
            setFocus={(e) => setFocus(e)}
            name="name"
            placeholder="Enter Name"
            rules={{
              required: "Required.",
            }}
          />

          <p className="font-poppins text-xs font-medium capitalize my-6">
            Modules and permissions
          </p>
        </div>

        <TrikePermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <TillerPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <BatteryPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <ChargerPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <TripPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <SessionPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <UserPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <LocationPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <InventoryPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <ServicePermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <FinancePermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <SettingsPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <MobileAppPermission
          permissions={permissions}
          setPermissions={setPermissions}
        />
      </div>

      <ConfirmRoleModal
        visible={confirmRoleModal?.visible}
        onCreate={handleSubmit(onCreate)}
        onCancel={() =>
          dispatch(
            updateHome({
              key: "confirmRoleModal",
              value: {
                visible: false,
                mode: "create",
              },
            }),
          )
        }
      />
    </CustomLayout>
  );
};

export default CreateRole;

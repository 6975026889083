import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomInput from "../../Core/Components/CustomInput";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import FilterItem from "../../Core/Components/FilterItem";
import PlusIcon from "../../Common/Svgs/PlusIcon";
import { LOCATION_TYPE } from "../Location/Data";

const CommodityListHeader = ({
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const { allLocations } = useSelector((state) => state.locations);

  const hubStations = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Commodity"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Hub Station"
            identifier="hub"
            contentData={hubStations}
            onSelect={onSelect}
            selectedValues={filters.hub}
            className="max-h-[200px] overflow-y-scroll"
            enableSearch
          />
        </div>

        <div>
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/add-commodity")}
          >
            <div className="flex item-center gap-x-2">
              <PlusIcon className="w-4 h-4 stroke-white" />
              <p className="font-poppins text-xs font-medium text-background-white">
                Add Commodity
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommodityListHeader;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
import React from "react";
import { Checkbox } from "antd";
import { mobileAppPermission } from "./data";
import PermissionTitle from "./PermissionTitle";
import { PERMISSIONS } from "../../Login/constants";
import MobileIcon from "../../../Common/Svgs/MobileIcon";
import {
  mobileHubManagerView,
  mobilePowerAfricaAgentView,
} from "./permissionFunctions";

const CONDITIONS = {
  REMOVE_ALL: [
    PERMISSIONS.APP_HUB_MANAGER_VIEW,
    PERMISSIONS.APP_NO_HUB_MANAGER_VIEW,
    PERMISSIONS.APP_POWER_AFRICA_AGENT_VIEW,
    PERMISSIONS.APP_NO_POWER_AFRICA_AGENT_VIEW,
    PERMISSIONS.APP_VIEW_AND_EDIT,
  ],
};

const MobileAppPermission = ({ permissions, setPermissions }) => {
  // check if the permissions needs to be disabled
  const disabled = permissions.includes(PERMISSIONS.APP_NO_ACCESS);

  const handleModulePermission = (permissionId) => {
    let local = [];

    if (permissionId === PERMISSIONS.APP_VIEW_AND_EDIT) {
      local = permissions.filter((item) => item !== PERMISSIONS.APP_NO_ACCESS);
    }

    // removing all the battery permissions
    if (permissionId === PERMISSIONS.APP_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.REMOVE_ALL.includes(item),
      );
    }

    setPermissions([...local, permissionId]);
  };

  const handleIndividualPermission = (permissionId) => {
    let local = [];

    if (permissions.includes(permissionId)) {
      local = permissions.filter((item) => item !== permissionId);
      setPermissions(local);
    } else {
      if (
        permissionId === PERMISSIONS.APP_HUB_MANAGER_VIEW ||
        permissionId === PERMISSIONS.APP_NO_HUB_MANAGER_VIEW
      ) {
        // handling either view or don't view QR
        mobileHubManagerView({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else if (
        permissionId === PERMISSIONS.APP_POWER_AFRICA_AGENT_VIEW ||
        permissionId === PERMISSIONS.APP_NO_POWER_AFRICA_AGENT_VIEW
      ) {
        // handling either view or don't view QR
        mobilePowerAfricaAgentView({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else {
        setPermissions((prev) => [...prev, permissionId]);
      }
    }
  };

  return (
    <div>
      <div className="py-3 px-6 w-full flex items-center gap-x-2 bg-primary">
        <MobileIcon className="text-background-white" />

        <p className="font-poppins text-sm text-background-white font-semibold uppercase">
          Mobile App
        </p>
      </div>

      {/* main module permission */}
      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={mobileAppPermission[0].title}
              description={mobileAppPermission[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {mobileAppPermission[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>App Views</p>
      </div>

      {/* individual view permission */}
      <div className="w-full">
        {mobileAppPermission.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabled && 0.5,
                        pointerEvents: disabled && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                      show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabled ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                        ${selected ? "font-medium" : "font-normal"}
                        `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAppPermission;

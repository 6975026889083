import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import TickIcon from "../../../Common/Svgs/TickIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Button from "../../../Core/Components/CustomButton";
import getStepDetails from "./getStepDetails";
import { activityType, assetType, powerSources } from "./constants";
import EndProcessModal from "./EndProcessModal";
import { createSession, editSession } from "../../../Redux/APIs/sessionsAPI";
import useMotorName from "./useMotorName";
import { updateSession } from "../../../Redux/Slices/sessions.slice";
import ToastMessageModal from "../../Tiller/ToastMessageModal";
import { updateTiller } from "../../../Redux/Slices/tillers.slice";

const Step = ({ number, title, selected = false, showLine = false }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div
        className="flex items-center justify-center w-[30px] h-[30px] rounded-full"
        style={{
          backgroundColor: selected ? "#085054" : "#DFDFDF",
        }}
      >
        {selected ? (
          <TickIcon size={14} className="text-yellow" />
        ) : (
          <p className="font-aileron text-10 leading-3 font-semibold text-dark-gray">
            {number}
          </p>
        )}
      </div>

      <p
        className={`font-aileron text-10 leading-3 font-semibold text-dark-gray ${
          selected ? "text-primary" : "text-dark-gray"
        }`}
      >
        {title}
      </p>

      {showLine && (
        <div
          className="w-[111px] h-[1px]"
          style={{
            backgroundColor: selected ? "#085054" : "#DFDFDF",
          }}
        />
      )}
    </div>
  );
};

const StepDescription = ({ handleCreate, handleCancel }) => {
  const { id } = useParams();

  const { createSessionStep } = useSelector((state) => state.sessions);

  const { title, description } = getStepDetails(createSessionStep);

  const getButtonText = () => {
    if (id && createSessionStep === 3) return "Save Session";
    if (createSessionStep === 3) return "Create Session";
    return "Next";
  };

  return (
    <div className="p-6 bg-background-white border-y border-light-grey flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-light-grey">
          <p className="font-aileron text-10 leading-3 font-semibold text-dark-gray">
            {createSessionStep}
          </p>
        </div>

        <div>
          <p className="font-poppins text-base font-medium capitalize">
            {title}
          </p>

          <p className="mt-1 text-10 leading-3 font-aileron font-normal text-dark-gray">
            {description}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-x-3">
        <Button
          size="filter"
          width="generateUser"
          colors="grey"
          onClick={handleCancel}
        >
          {createSessionStep === 1 ? "Cancel" : "Previous"}
        </Button>

        <Button
          size="filter"
          width="generateUser"
          type="submit"
          onClick={handleCreate}
        >
          {getButtonText()}
        </Button>
      </div>
    </div>
  );
};

const CreateSessionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentSession, createSessionStep, showToastMessage } = useSelector(
    (state) => state.sessions,
  );

  const [endProcessModal, setEndProcessModal] = useState(false);
  const [sessionBatteries, setSessionBatteries] = useState([]);

  // find motor name while editing a session
  const localMotorName = useMotorName(currentSession?.motorId);

  // form state on main component. every step will use this form state (passed as props)
  const { control, formState, handleSubmit, setValue, clearErrors, watch } =
    useForm({
      defaultValues: {
        farmerId: id ? currentSession?.farmerId : "",
        farmerName: id
          ? `${currentSession?.farmerFirstName} ${currentSession?.farmerLastName}`
          : "",
        farmLocationId: id ? `${currentSession?.farmerLocationId}` : "",
        farmLocationName: id
          ? `Farm 1 (${currentSession?.farmLat}, ${currentSession?.farmLong})`
          : "",
        farmLat: null,
        farmLong: null,
        area: id ? currentSession?.area : null,
        estimatedCostPerSqM: id ? currentSession?.estimatedCostPerSqm : null,
        totalCost: id ? currentSession?.totalCost : null,
        activityId: activityType[0].id,
        activityName: activityType[0].value,
        activityDate: id ? currentSession.activityDate : null,
        startTime: id ? currentSession?.timeSlot : "",
        hubId: id ? currentSession?.hubId : null,
        hubName: id ? currentSession?.hubName : "",
        agentId: id ? currentSession?.agentId : null,
        agentName: id
          ? `${currentSession?.agentFirstName} ${currentSession?.agentLastName}`
          : "",
        assetTypeId: assetType[0].id,
        assetTypeName: assetType[0].value,
        assetId: id ? currentSession?.assetId : null,
        assetRegistrationNo: id ? currentSession?.assetRegistrationNo : "",
        powerSourceId: powerSources[0].id,
        powerSourceName: powerSources[0].value,
        motorTypeId: id ? currentSession?.motorId : null,
        motorTypeName: id ? localMotorName : "",
      },
    });

  const handleCancel = () => {
    // first step has cancel CTA
    if (createSessionStep === 1) {
      setEndProcessModal(true);
      return;
    }

    // other steps has previous CTA
    // setCurrentStep((prev) => prev - 1);
    dispatch(
      updateSession({
        key: "createSessionStep",
        value: createSessionStep - 1,
      }),
    );
  };

  const handleCreate = async (e) => {
    // final step has create session CTA
    if (createSessionStep === 3) {
      const payload = e;
      payload.batteries = sessionBatteries;

      if (id) {
        payload.sessionId = id;
        await dispatch(editSession(payload));

        dispatch(
          updateSession({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Session edited",
                description: `Session ${id} was edited successfully`,
              },
            },
          }),
        );
      } else {
        await dispatch(createSession(payload));

        dispatch(
          updateSession({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Session created",
                description: "1 Session was created successfully",
              },
            },
          }),
        );

        dispatch(
          updateSession({
            key: "createSessionStep",
            value: 1,
          }),
        );
      }

      navigate("/sessions");
      return;
    }

    // other steps has next CTA
    // setCurrentStep((prev) => prev + 1);
    dispatch(
      updateSession({
        key: "createSessionStep",
        value: createSessionStep + 1,
      }),
    );
  };

  const renderSteps = () => {
    switch (createSessionStep) {
      case 1:
        return (
          <Step1
            control={control}
            formState={formState}
            setValue={setValue}
            clearErrors={clearErrors}
            watch={watch}
          />
        );

      case 2:
        return (
          <Step2
            control={control}
            formState={formState}
            setValue={setValue}
            clearErrors={clearErrors}
            watch={watch}
          />
        );

      case 3:
        return (
          <Step3
            watch={watch}
            sessionBatteries={sessionBatteries}
            setSessionBatteries={setSessionBatteries}
          />
        );

      default:
        return (
          <Step1
            control={control}
            formState={formState}
            setValue={setValue}
            clearErrors={clearErrors}
            watch={watch}
          />
        );
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showToastMessage.visible) {
        dispatch(
          updateSession({
            key: "showToastMessage",
            value: { visible: false, data: {} },
          }),
        );
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  return (
    <CustomLayout pageName="Create A Session" hideFooter>
      <div className="px-4 py-3 bg-background-white flex items-center justify-between">
        <div className="flex items-center gap-x-1">
          <Step
            showLine
            number={1}
            title="Schedule Session"
            selected={createSessionStep > 1}
          />

          <Step
            showLine
            number={2}
            title="Assign Asset & Agent"
            selected={createSessionStep > 2}
          />

          <Step
            number={3}
            title="Associate Batteries"
            selected={createSessionStep > 3}
          />
        </div>

        <button type="button" onClick={() => setEndProcessModal(true)}>
          <CloseIcon className="w-6 h-6 stroke-dark-gray" />
        </button>
      </div>

      <StepDescription
        handleCancel={handleCancel}
        handleCreate={handleSubmit(handleCreate)}
      />

      {/* body of the step */}
      {renderSteps()}

      <EndProcessModal
        visible={endProcessModal}
        onCancel={() => setEndProcessModal(false)}
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateTiller({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
        isError={showToastMessage?.data?.isError}
      />
    </CustomLayout>
  );
};

export default CreateSessionScreen;

/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import {
  batteryPercentageFilters,
  statusFilters,
  toggleFilters,
} from "../Data";
import MoreTrikeFilters from "./MoreTrikeFilters";
import MoreColumnsDropdown from "../MoreColumnsDropdown";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { client } from "../../../Utils/axiosClient";
import { LOCATION_TYPE } from "../../Location/Data";

const TrikesListHeader = ({
  showKpi,
  setShowKpi,
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const [focus, setFocus] = useState(false);
  const [driversInSystem, setDriversInSystem] = useState([]);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const getDrivers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/drivers/get-drivers`,
        {
          params: {
            limit: 100,
          },
        },
      );
      setDriversInSystem([...response.data.result]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDrivers();
  }, [getDrivers]);

  return (
    <div className="bg-background-white px-6 py-2.5 border-t-[0.5px] border-light-grey flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search Trikes or Drivers"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />

        <FilterItem
          label="Hub Station"
          identifier="hub"
          contentData={hubFilters}
          onSelect={onSelect}
          selectedValues={filters.hub}
          className="max-h-[200px] overflow-y-scroll"
          enableSearch
        />

        <FilterItem
          label="Battery %"
          identifier="battery"
          className="max-h-[200px] overflow-y-scroll"
          contentData={batteryPercentageFilters}
          onSelect={onSelect}
          selectedValues={filters.battery}
        />

        <FilterItem
          label="Status"
          identifier="status"
          className="max-h-[200px] overflow-y-scroll"
          customValue
          contentData={statusFilters}
          onSelect={onSelect}
          selectedValues={filters.status}
        />

        <FilterItem
          label="On/Off"
          identifier="toggle"
          className="max-h-max"
          customValue
          contentData={toggleFilters}
          onSelect={onSelect}
          selectedValues={filters.toggle}
        />

        <div className="bg-light-grey w-[1px] h-5 mx-1" />

        <MoreTrikeFilters
          filters={filters}
          setFilters={setFilters}
          drivers={driversInSystem}
        />

        <MoreColumnsDropdown />
      </div>

      <div className="flex items-center gap-x-3">
        <button
          type="button"
          className="flex items-center justify-center w-8 h-6 rounded-md bg-[#eeeeee]"
          onClick={() => setShowKpi(!showKpi)}
        >
          <ChevronIcon
            className={`w-4 h-4 stroke-black ${
              showKpi ? "rotate-90" : "-rotate-90"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default TrikesListHeader;

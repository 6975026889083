import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EmptyHandler = ({ redirectedPath = -1 }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectedPath);
  }, [navigate, redirectedPath]);

  return <div />;
};

export default EmptyHandler;

import React from "react";

const SessionSummaryIcon = ({ size = 20, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.895"
          d="M7.497 18.333h5c4.167 0 5.834-1.666 5.834-5.833v-5c0-4.167-1.667-5.833-5.834-5.833h-5c-4.166 0-5.833 1.666-5.833 5.833v5c0 4.167 1.667 5.833 5.833 5.833M13.125 7.5h-6.25M13.125 12.5h-6.25"
        />
      </svg>
    </span>
  );
};

export default SessionSummaryIcon;

/* eslint-disable no-console */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../Core/Components/CustomInput";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import FilterItem from "../../Core/Components/FilterItem";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { LOCATION_TYPE } from "../Location/Data";
import { statusFilters } from "./data";

const SessionListHeader = ({
  showKpi,
  setShowKpi,
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const { allSystemUsers } = useSelector((state) => state.loggedInUser);

  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  // get all the agents
  const agents = allSystemUsers?.map((item) => ({
    id: item?.id,
    value: `${item?.firstName} ${item?.lastName}`,
  }));

  const hasPermission = usePermission([PERMISSIONS.SESSION_CREATE]);

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-t-[0.5px] border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Session ID, Agent"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Hub Station"
            identifier="hubs"
            contentData={hubFilters}
            onSelect={onSelect}
            selectedValues={filters.hubs}
            className="max-h-[200px] overflow-y-scroll"
            enableSearch
          />

          <FilterItem
            label="Agent"
            identifier="agents"
            className="max-h-[200px] overflow-y-scroll"
            contentData={agents}
            onSelect={onSelect}
            selectedValues={filters.agents}
          />

          <FilterItem
            label="Status"
            identifier="status"
            customValue
            className="max-h-max"
            contentData={statusFilters}
            onSelect={onSelect}
            selectedValues={filters.status}
          />
        </div>

        <div className="flex items-center gap-x-3">
          {hasPermission && (
            <button
              type="button"
              className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
              onClick={() => navigate("/sessions/create-session")}
            >
              <div className="flex item-center gap-x-2">
                <img src="/assets/icons/add.svg" alt="" />
                <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                  Create A Session
                </p>
              </div>
            </button>
          )}

          <button
            type="button"
            className="flex items-center justify-center w-8 h-6 rounded-md bg-[#eeeeee]"
            onClick={() => setShowKpi(!showKpi)}
          >
            <ChevronIcon
              className={`w-4 h-4 stroke-black ${
                showKpi ? "rotate-90" : "-rotate-90"
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionListHeader;

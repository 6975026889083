/* eslint-disable import/no-cycle */
import React from "react";
import { QR_CODE_TYPE } from "./data";
import CustomTags from "../../Core/Components/CustomTags";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import BatteryIcon from "../../Common/Svgs/BatteryIcon";
import UserIcon from "../../Common/Svgs/UserIcon";
import ChargerIcon from "../../Common/Svgs/ChargerIcon";
import TillerIcon from "../../Common/Svgs/TillerIcon";

const getQRCodeStatus = (type) => {
  switch (type) {
    case QR_CODE_TYPE.TRIKE:
      return (
        <CustomTags width="qrCode" colors="qrTrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="text-primary" />
            <p className="font-poppins text-xs font-medium text-primary pt-0.5">
              Trike
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.BATTERY:
      return (
        <CustomTags width="qrCode" colors="qrBattery">
          <div className="flex items-center gap-x-2">
            <BatteryIcon className="w-4 h-4 stroke-yellow-dark fill-none" />
            <p className="font-poppins text-xs font-medium text-yellow-dark">
              Battery
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.FARMER:
      return (
        <CustomTags width="qrCode" colors="qrFarmer">
          <div className="flex items-center gap-x-2">
            <UserIcon className="w-4 h-4 stroke-user-pink fill-none" />
            <p className="font-poppins text-xs font-medium text-user-pink pt-0.5">
              Partner
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.CHARGER:
      return (
        <CustomTags width="qrCode" colors="qrCharger">
          <div className="flex items-center gap-x-2">
            <ChargerIcon size={16} className="text-[#0892BD]" />
            <p className="font-poppins text-xs font-medium text-[#0892BD] pt-0.5">
              Charger
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.TILLER:
      return (
        <CustomTags width="qrCode" colors="qrTiller">
          <div className="flex items-center gap-x-2">
            <TillerIcon size={16} className="text-alert-red fill-none" />
            <p className="font-poppins text-xs font-medium text-alert-red pt-0.5">
              Tiller
            </p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <CustomTags width="qrCode" colors="qrTrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="text-primary" />
            <p className="font-poppins text-xs font-medium text-primary pt-0.5">
              Trike
            </p>
          </div>
        </CustomTags>
      );
  }
};

export default getQRCodeStatus;

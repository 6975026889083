const useMotorName = (motorId) => {
  if (!motorId) return "";

  switch (motorId) {
    case 1:
      return "Petrol Engine";
    case 2:
      return "4kW BLDC Motor";
    case 3:
      return "⁠2.2kW PSM Motor";
    case 4:
      return "2kW BLDC Motor";
    default:
      return "Petrol Engine";
  }
};

export default useMotorName;

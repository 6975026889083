/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { client } from "../../../Utils/axiosClient";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { statusFilters } from "../Data/TripsListData";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { LOCATION_TYPE } from "../../Location/Data";

const TripListHeader = ({
  showKpi,
  setShowKpi,
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const { allLocations } = useSelector((state) => state.locations);
  const { allTrikes } = useSelector((state) => state.trikes);

  // get all the hubs
  const hubFilters = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const hasPermission = usePermission([PERMISSIONS.TRIP_CREATE]);

  const trikesList = allTrikes.map((trike) => ({
    id: trike.id,
    value: trike.registrationNo,
  }));

  const [focus, setFocus] = useState(false);
  const [driversInSystem, setDriversInSystem] = useState([]);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const getFilterOptionsData = useCallback(async () => {
    try {
      const driverResponse = await client.get(
        `${process.env.REACT_APP_API_URL}/drivers/get-drivers`,
      );

      let drivers =
        driverResponse.data.result?.map((driver) => ({
          id: driver.id,
          value: `${driver.firstName} ${driver.lastName}`,
        })) || [];

      drivers = drivers.length
        ? drivers.sort((a, b) => a?.value?.localeCompare(b?.value))
        : [];

      setDriversInSystem([...drivers]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getFilterOptionsData();
  }, [getFilterOptionsData]);

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-t-[0.5px] border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Trip ID, Driver"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Hub Station"
            identifier="hubs"
            contentData={hubFilters}
            onSelect={onSelect}
            selectedValues={filters.hubs}
            className="max-h-[200px] overflow-y-scroll"
            enableSearch
          />

          <FilterItem
            label="Driver"
            identifier="drivers"
            className="max-h-[200px] overflow-y-scroll"
            contentData={driversInSystem}
            onSelect={onSelect}
            selectedValues={filters.drivers}
          />

          <FilterItem
            label="Trike No."
            identifier="trikes"
            contentData={trikesList}
            onSelect={onSelect}
            selectedValues={filters.trikes}
            className="max-h-[200px] overflow-y-scroll"
          />

          <FilterItem
            label="Status"
            identifier="status"
            customValue
            className="max-h-max"
            contentData={statusFilters}
            onSelect={onSelect}
            selectedValues={filters.status}
          />
        </div>

        <div className="flex items-center gap-x-3">
          {hasPermission && (
            <button
              type="button"
              className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
              onClick={() => navigate("/trips/create-trip")}
            >
              <div className="flex item-center gap-x-2">
                <img src="/assets/icons/add.svg" alt="" />
                <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                  Create A Trip
                </p>
              </div>
            </button>
          )}

          <button
            type="button"
            className="flex items-center justify-center w-8 h-6 rounded-md bg-[#eeeeee]"
            onClick={() => setShowKpi(!showKpi)}
          >
            <ChevronIcon
              className={`w-4 h-4 stroke-black ${
                showKpi ? "rotate-90" : "-rotate-90"
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripListHeader;

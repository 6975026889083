/* eslint-disable no-console */
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import Button from "../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../Core/Components/CustomDropdown";
import useHubStationOnAdd from "../../Utils/useHubStationOnAdd";
import { addTiller, editTiller } from "../../Redux/APIs/tillersAPI";
import { updateTiller } from "../../Redux/Slices/tillers.slice";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddTiller = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentTiller } = useSelector((state) => state.tillers);

  const hubStations = useHubStationOnAdd();

  const { handleSubmit, control, formState, setValue, clearErrors, setError } =
    useForm({
      defaultValues: {
        registrationNo: id ? currentTiller.registrationNo : "",
        modelNo: id ? currentTiller.modelNo : "",
        assetTag: id ? currentTiller.assetTag : "",
        hubId: id ? currentTiller.hubId : "",
        hubName: id ? currentTiller.hubName : "",
      },
    });

  const [focus, setFocus] = useState({
    registrationNo: false,
    modelNo: false,
    assetTag: false,
  });

  const rules = {
    required: "Required.",
  };

  const hubNameError = formState?.errors?.hubName?.message;

  const onCancel = () => navigate(-1);

  const onAdd = async (e) => {
    const payload = e;
    if (id) {
      payload.tillerId = id;

      const editResponse = await dispatch(editTiller(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateTiller({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Tiller Edited",
                description: "1 tiller was edited successfully.",
              },
            },
          }),
        );

        navigate(-1);
      }
    } else {
      const addResponse = await dispatch(addTiller(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateTiller({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Tiller Added",
                description: "1 new tiller was added successfully.",
              },
            },
          }),
        );

        navigate(-1);
      }
    }
  };

  return (
    <CustomLayout pageName="Assets" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit tiller" : "Add a new tiller"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Save" : "Add"} Tiller
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full bg-background-white p-6 flex flex-col gap-y-6">
        <LabelAndInput
          label="Registration No."
          control={control}
          formState={formState}
          name="registrationNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.registrationNo}
          setFocus={(state) => setFocus({ ...focus, registrationNo: state })}
        />

        <LabelAndInput
          label="Model Number"
          control={control}
          formState={formState}
          name="modelNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.modelNo}
          setFocus={(state) => setFocus({ ...focus, modelNo: state })}
        />

        <LabelAndInput
          label="Asset Tag"
          control={control}
          formState={formState}
          name="assetTag"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.assetTag}
          setFocus={(state) => setFocus({ ...focus, assetTag: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[200px] overflow-y-scroll"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              hubNameError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddTiller;

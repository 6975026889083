/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import ArrowIcon from "../../../Common/Svgs/ArrowIcon";
import TickIcon from "../../../Common/Svgs/TickIcon";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import { client } from "../../../Utils/axiosClient";
import { getTillerById } from "../../../Redux/APIs/tillersAPI";

const IndexPassIcon = () => {
  return (
    <div className="w-6 h-6 rounded-full flex items-center justify-center bg-green">
      <TickIcon size={14} className="text-yellow" />
    </div>
  );
};

const SessionChecklistModal = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentSession } = useSelector((state) => state.sessions);
  const { currentTiller } = useSelector((state) => state.tillers);

  const [checklist, setChecklist] = useState([]);

  const getChecklist = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/session/summary-checklist`,
        {
          params: {
            sessionId: currentSession.id,
          },
        },
      );

      const checklists = response.data.checklist?.map((item) => ({
        id: item.partId,
        name: item.partName,
      }));

      setChecklist([...checklists]);
    } catch (error) {
      console.log(error);
    }
  }, [currentSession]);

  const handleKnownIssues = () => {
    dispatch(getTillerById({ tillerId: currentSession?.assetId }));
    navigate(`/assets/tiller-known-issue/${currentTiller?.registrationNo}`);
    onCancel();
  };

  useEffect(() => {
    if (visible) {
      getChecklist();
    }
  }, [visible, getChecklist]);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="662px">
      <div
        className="p-6"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="max-h-[685px] overflow-y-scroll hide-scrollbar">
          <div className="flex items-center justify-between">
            <p className="font-poppins text-base font-medium">
              Safety Che cklist Form
            </p>

            <button type="button" onClick={onCancel}>
              <CloseIcon className="stroke-dark-gray w-6 h-6" />
            </button>
          </div>

          <p className="font-aileron text-sm font-normal text-[#6F6F6F]">
            View the status of the tiller recorded at the parking lot.
          </p>

          <button
            type="button"
            className="mt-2 py-2 px-4 rounded-md bg-primary-10 text-primary"
            onClick={handleKnownIssues}
          >
            <div className="flex items-center gap-x-2">
              <p className="font-poppins text-sm font-medium">Known Issues</p>
              <ArrowIcon size={20} className="rotate-90" strokeWidth={1} />
            </div>
          </button>

          <div className="my-6">
            {checklist.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between py-3 border-b border-[#E7E7E7] ${
                  index === 0 && "border-t"
                }`}
              >
                <div className="flex items-center gap-x-2">
                  <IndexPassIcon />

                  <p className="font-poppins text-sm font-medium capitalize">
                    {item?.name}
                  </p>
                </div>

                <div className="flex items-center gap-x-6">
                  <div className="w-10 h-10 flex items-center justify-center rounded-lg bg-[#E7E7E7]">
                    <CloseIcon className="w-4 h-4 stroke-[#6F6F6F]" />
                  </div>

                  <div className="w-10 h-10 flex items-center justify-center rounded-lg bg-green">
                    <TickIcon size={16} className="text-white" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default SessionChecklistModal;

/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { TRIKE_STATUS } from "../Data";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import { QR_CODE_TYPE } from "../../QRCode/data";
import { updateServices } from "../../../Redux/Slices/services.slice";
import useTrikeThreeDotsOptions from "./useTrikeThreeDotsOptions";

const ListDropdown = ({ detail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.trikes);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useTrikeThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const filteredOptions = () => {
    let options = [...threeDotsOptions];

    // if issues are present then do not show add issue option
    if (detail.knownIssues.length) {
      options = options.filter((item) => item.id !== 3);
    }

    // if the status is service then do not show add to service option
    if (detail.status == TRIKE_STATUS.SERVICE) {
      options = options.filter((item) => item.id !== 5);
    }

    // if the status is not service then do not show view service option
    if (detail.status != TRIKE_STATUS.SERVICE) {
      options = options.filter((item) => item.id !== 9);
    }

    // do not show dispatch option
    if (detail.status == TRIKE_STATUS.DISPATCHED) {
      options = options.filter((item) => item.id !== 7);
    }
    // do not show hub station option
    else if (detail.status == TRIKE_STATUS.HUB_STATION) {
      options = options.filter((item) => item.id !== 8);
    }
    // if status is other than dispatch or parking lot, then do not show both the options (dispatch and parking lot)
    else {
      options = options.filter((item) => item.id !== 7 && item.id !== 8);
    }

    return options;
  };

  const onOptionClick = async (optionId) => {
    dispatch(
      updateTrikes({
        key: "currentTrike",
        value: detail,
      }),
    );

    setOpen(false);

    switch (optionId) {
      // 1 -> view details
      case 1:
        navigate(`/assets/trikes/${detail.registrationNo}`);
        break;

      // 2 -> edit details
      case 2:
        navigate(`/assets/edit-trike/${detail.id}`);
        break;

      // 3 -> add issues
      case 3:
        navigate(`/assets/trike-known-issue/${detail.registrationNo}`);
        break;

      // 4 -> view QR code
      case 4:
        dispatch(
          updateQrCode({
            key: "viewModal",
            value: {
              visible: true,
              data: {
                assetId: detail.registrationNo,
                assetType: QR_CODE_TYPE.TRIKE,
                metaData: {
                  trikeid: detail.id,
                },
              },
            },
          }),
        );

        break;

      // 5 -> add to service
      case 5:
        dispatch(
          updateServices({
            key: "updatingService",
            value: false,
          }),
        );

        navigate(`/add-to-service/trikes/${detail.registrationNo}`);

        break;

      // 6 -> delete trike
      case 6:
        dispatch(
          updateTrikes({
            key: "deleteTrikeModal",
            value: {
              visible: true,
              id: detail.id,
              registrationNo: detail.registrationNo,
            },
          }),
        );

        break;

      // 7 -> update to dispatch
      case 7:
        dispatch(
          updateTrikes({
            key: "selectBatteryModal",
            value: {
              visible: true,
              trike: detail,
            },
          }),
        );

        break;

      // 8 -> move to hub station
      case 8:
        dispatch(
          updateTrikes({
            key: "moveToHubStationModal",
            value: {
              visible: true,
              trike: detail,
            },
          }),
        );

        break;

      // 9 -> view service
      case 9:
        navigate("/service");
        break;

      // 10 -> view issues (if added) or else throw error
      case 10:
        if (detail.knownIssues.length) {
          navigate(`/assets/trike-known-issue/${detail.registrationNo}`);
        } else {
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: {
                visible: true,
                data: {
                  title: "No Issues Found",
                  description: `Trike ${detail.registrationNo} has no issues added.`,
                  icon: "error",
                },
              },
            }),
          );
        }

        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateTrikes({
        key: "threeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && detail.id === threeDotsPopoverId}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "164px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {filteredOptions().map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                option.id === 6
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              // style={{
              //   borderBottom:
              //     (totalOptions.length > 2 && index === 1) ||
              //     (totalOptions.length > 4 &&
              //       index === 3 &&
              //       "1px solid #DFDFDF"),
              // }}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {/* {option.id === 3 ? getCustomOptionIcon() : option.icon} */}

              {option.icon}

              <p
                className={`font-aileron text-xs font-semibold capitalize whitespace-nowrap ${
                  option.id === 6
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {/* {option.id === 3 || option.id === 5
                  ? getCustomOptionValue(option.id)
                  : option.value} */}
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="w-full flex items-center justify-center cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;

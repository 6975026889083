/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { KPIData, sessionColumns } from "./data";
import CustomKPI from "../../Core/Components/CustomKpi";
import CustomTable from "../../Core/Components/CustomTable";
import SessionListHeader from "./SessionListHeader";
import TillerIcon from "../../Common/Svgs/TillerIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { updateSession } from "../../Redux/Slices/sessions.slice";
import ToastMessageModal from "./ToastMessageModal";
import DeleteSessionModal from "./DeleteSessionModal";
import { getSessionKPI, getSessions } from "../../Redux/APIs/sessionsAPI";
import useDebounce from "../../Core/hooks/useDebounce";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import { getAllDrivers } from "../../Redux/APIs/usersAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const SessionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showToastMessage, deleteSessionModal, sessionList, kpi } =
    useSelector((state) => state.sessions);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [showKpi, setShowKpi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hubs: [],
    agents: [],
    status: [],
  });

  // check if the user has permission to acccess the module
  const hasPermission = usePermission([
    PERMISSIONS.TRIP_VIEW,
    PERMISSIONS.TRIP_VIEW_AND_EDIT,
  ]);

  const debounceSearch = useDebounce(search, 500);

  const handleRowClick = (record) => {
    if (hasPermission) {
      dispatch(
        updateSession({
          key: "currentSession",
          value: record,
        }),
      );
      navigate(`/sessions/${record.id}`);
    }
  };

  const getTableContainerStyle = () => {
    if (showKpi) return "h-[calc(100vh-72px-69px-40px)] overflow-y-scroll";
    return "h-[calc(100vh-72px-160px-69px-40px)] overflow-y-scroll";
  };

  useEffect(() => {
    dispatch(getSessionKPI());
    dispatch(
      updateSession({
        key: "createSessionStep",
        value: 1,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hubs.length) filterObj.hubs = filters.hubs;
    if (filters.agents.length) filterObj.agents = filters.agents;
    if (filters.status.length) filterObj.status = filters.status;

    dispatch(getSessions(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showToastMessage?.visible) {
        dispatch(
          updateSession({
            key: "showToastMessage",
            value: {
              visible: false,
              data: {},
            },
          }),
        );
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllDrivers());
  }, [dispatch]);

  return (
    <CustomLayout pageName="Sessions">
      <div className="relative bg-background">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-5.5">
          {KPIData(kpi)?.map((i) => (
            <CustomKPI
              key={i?.id}
              image={i?.image}
              color="bg-primary"
              title={i?.title}
              count={i?.count}
              total={i?.total}
              icon={i?.icon}
              handleOnClick={i.onClick}
            />
          ))}
        </div>

        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[158px]" : ""
          }  transition-transform duration-500`}
        >
          <SessionListHeader
            showKpi={showKpi}
            setShowKpi={setShowKpi}
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
            setCurrentPage={setCurrentPage}
          />

          <div className={getTableContainerStyle()}>
            <CustomTable
              // scrollX={0}
              disableScroll
              columns={sessionColumns}
              emptyState={<TillerIcon size={16} className="text-dark-gray" />}
              data={sessionList.result}
              onRowClick={handleRowClick}
              rowClassName={`${
                hasPermission ? "group cursor-pointer" : "group"
              }`}
              pagination={{
                pageSize: LOCAL_DEFAULT_LIMIT,
                total: sessionList.totalRecords,
                showSizeChanger: false,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                current: currentPage,
                itemRender: (current, type, originalElement) => {
                  const lastPage = sessionList.totalRecords / current;

                  if (type === "prev" && current === 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "prev" && current > 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next" && lastPage <= 10) {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{ pointerEvents: "none" }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next") {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  return originalElement;
                },
              }}
            />
          </div>
        </div>

        <DeleteSessionModal
          visible={deleteSessionModal}
          onCancel={() =>
            dispatch(updateSession({ key: "deleteSessionModal", value: false }))
          }
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <ToastMessageModal
          visible={showToastMessage?.visible}
          onCancel={() =>
            dispatch(
              updateSession({
                key: "showToastMessage",
                value: {
                  visible: false,
                  data: {},
                },
              }),
            )
          }
          title={showToastMessage?.data?.title}
          description={showToastMessage?.data?.description}
        />
      </div>
    </CustomLayout>
  );
};

export default SessionList;

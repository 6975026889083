import { createSlice } from "@reduxjs/toolkit";
import {
  addKnownIssues,
  addTiller,
  createTillerChecklist,
  deleteTiller,
  editTiller,
  getAllTillers,
  getTillerById,
  getTillerChecklist,
  getTillerKPI,
  getTillers,
  resolveKnownIssue,
  updateKnownIssues,
  updateTillerChecklist,
} from "../APIs/tillersAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  tillerChecklist: [],
  kpiData: {
    total: 0,
    inSession: 0,
    service: 0,
  },
  tillerList: {
    result: [],
    totalRecords: 0,
  },
  allTillers: [],
  currentTiller: {},
  threeDotsPopoverId: 0,
  showToastMessage: {
    visible: false,
    data: {},
  },
  deleteTillerModal: false,
};

export const tillersSlice = createSlice({
  name: "tillers",
  initialState,
  reducers: {
    updateTiller: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // add tiller
    builder.addCase(addTiller.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTiller.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addTiller.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get tillers
    builder.addCase(getTillers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTillers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tillerList = action.payload;
    });
    builder.addCase(getTillers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get tiller by id
    builder.addCase(getTillerById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTillerById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.currentTiller = action.payload.tiller;
    });
    builder.addCase(getTillerById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get tiller KPIs
    builder.addCase(getTillerKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTillerKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpi;
    });
    builder.addCase(getTillerKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // edit tiller
    builder.addCase(editTiller.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editTiller.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editTiller.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // delete tiller
    builder.addCase(deleteTiller.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTiller.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteTiller.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all tillers
    builder.addCase(getAllTillers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTillers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allTillers = action.payload.result;
    });
    builder.addCase(getAllTillers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get tiller checklist
    builder.addCase(getTillerChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTillerChecklist.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tillerChecklist = action.payload.checklist;
    });
    builder.addCase(getTillerChecklist.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // create tiller checklist
    builder.addCase(createTillerChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTillerChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(createTillerChecklist.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // create tiller checklist
    builder.addCase(updateTillerChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTillerChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateTillerChecklist.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add tiller known issues
    builder.addCase(addKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addKnownIssues.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update tiller known issues
    builder.addCase(updateKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateKnownIssues.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // resolve tiller known issues
    builder.addCase(resolveKnownIssue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resolveKnownIssue.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(resolveKnownIssue.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateTiller } = tillersSlice.actions;

export default tillersSlice.reducer;

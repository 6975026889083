/* eslint-disable new-cap */
import React from "react";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";

const getOngoingSessionIcon = () => {
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="66"
          height="74"
          fill="none"
          viewBox="0 0 66 74"
        >
          <g filter="url(#filter0_f_8506_209511)">
            <path
              fill="#000"
              fillOpacity="0.26"
              d="M11.232 27.473c5.118-22.248 38.447-22.222 43.539.026 2.987 13.05-5.221 24.097-12.417 30.93-7.794 7.402-10.91 7.454-18.73 0-7.17-6.833-15.38-17.905-12.392-30.956"
            />
          </g>
          <path
            fill="#085054"
            stroke="#085054"
            strokeWidth="1.5"
            d="M13.45 27.81c4.596-20.207 34.533-20.183 39.106.023 2.683 11.854-4.69 21.887-11.153 28.094-7 6.722-9.8 6.769-16.824 0-6.44-6.207-13.813-16.264-11.13-28.117Z"
          />
          <path
            stroke="#FAD152"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M36 26v10c0 1.1-.9 2-2 2H23v-8c0-2.21 1.79-4 4-4z"
          />
          <path
            stroke="#FAD152"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M43 38v3c0 1.66-1.34 3-3 3h-1c0-1.1-.9-2-2-2s-2 .9-2 2h-4c0-1.1-.9-2-2-2s-2 .9-2 2h-1c-1.66 0-3-1.34-3-3v-3h11c1.1 0 2-.9 2-2v-7h1.84c.72 0 1.38.39 1.74 1.01L41.29 33H40c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1z"
          />
          <path
            stroke="#FAD152"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M29 46a2 2 0 1 0 0-4 2 2 0 0 0 0 4M37 46a2 2 0 1 0 0-4 2 2 0 0 0 0 4M43 36v2h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1.29z"
          />
          <defs>
            <filter
              id="filter0_f_8506_209511"
              width="64.797"
              height="73.2"
              x="0.602"
              y="0.8"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                result="effect1_foregroundBlur_8506_209511"
                stdDeviation="5"
              />
            </filter>
          </defs>
        </svg>
      </div>,
    ),
  });
};

export default getOngoingSessionIcon;

import React from "react";
import dayjs from "dayjs";
import ClockIcon from "../../Common/Svgs/ClockIcon";
import OperationsIcon from "../../Common/Svgs/OperationsIcon";
import CheckCircleIcon from "../../Common/Svgs/CheckCircleIcon";
import getSessionStatus from "./getSessionStatus";
import CustomTooltip from "../../Core/Components/CustomTooltip";
import { SESSION_STATUS } from "./constants";
import ListDropdown from "./ListDropdown";

export const sessionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Session ID
      </div>
    ),
    dataIndex: "id",
    // width: "140px",
    sorter: (a, b) => a.id - b.id,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Agent
      </div>
    ),
    dataIndex: "agentFirstName",
    sorter: (a, b) => a.agentFirstName.localeCompare(b.agentFirstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text ? `${text} ${record.agentLastName}` : "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Asset ID
      </div>
    ),
    sorter: (a, b) =>
      a?.assetRegistrationNo?.localeCompare(b?.assetRegistrationNo),
    dataIndex: "assetRegistrationNo",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Farmer & Location
      </div>
    ),
    dataIndex: "farmerFirstName",
    sorter: (a, b) => a?.farmerFirstName?.localeCompare(b?.farmerFirstName),
    // width: "200px",
    render: (text, record) => (
      <button type="button" onClick={(e) => e.stopPropagation()}>
        <CustomTooltip
          tooltipTitle={`${record.farmLat}, ${record.farmLong}`}
          bgColor="#fff"
          textColor="text-dark-gray"
          placement="bottomLeft"
          padding="8px"
          trigger="click"
        >
          <div className="flex items-center cursor-pointer gap-x-1">
            <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
              {text ? `${text} ${record.farmerLastName}` : "--"}
            </div>
            <img src="/assets/icons/chevron-down.svg" alt="" />
          </div>
        </CustomTooltip>
      </button>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Area
      </div>
    ),
    dataIndex: "area",
    // width: "150px",
    sorter: (a, b) => a.area - b.area,
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {record.status === SESSION_STATUS.COMPLETED
          ? `${record.actualArea} Sq.M`
          : `${record.estimatedArea} Sq.M (E)`}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Session Cost
      </div>
    ),
    dataIndex: "totalCost",
    sorter: (a, b) => a.totalCost - b.totalCost,
    // width: "140px",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {record.status === SESSION_STATUS.COMPLETED
          ? `${record.actualTotalCost} Ksh`
          : `${record.estimatedTotalCost} Ksh (E)`}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date & Time Slot
      </div>
    ),
    dataIndex: "activityDate",
    sorter: (a, b) => a?.activityDate?.localeCompare(b?.activityDate),
    // width: "140px",
    render: (text, record) => (
      <div>
        <p className="text-xs font-semibold font-aileron leading-[14.5px] text-dark-gray capitalize">
          {dayjs(text).format("ddd, MMM DD YYYY")}
        </p>

        <p className="text-10 font-normal font-aileron leading-3 text-dark-gray uppercase">
          {record.status === SESSION_STATUS.COMPLETED
            ? `${dayjs(record.startTime).format("hh:mm a")} - ${dayjs(
                record.endTime,
              ).format("hh:mm a")}`
            : record.timeSlot}
        </p>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    // width: "170px",
    render: (text) => <div>{getSessionStatus(text)}</div>,
  },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const sessionData = [
  {
    id: 1,
    agent: "Phillipe",
    assetId: "T-1230",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 10,
    totalCost: 800,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 1,
  },
  {
    id: 2,
    agent: "Phillipe",
    assetId: "T-1313",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 2,
  },
  {
    id: 3,
    agent: "Viola Toperesu",
    assetId: "T-4123",
    farmerName: "Alice Kerubo",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 2,
  },
  {
    id: 4,
    agent: "Viola Toperesu",
    assetId: "T-123",
    farmerName: "Alice Kerubo",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 1,
  },
  {
    id: 5,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 3,
  },
  {
    id: 6,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 3,
  },
  {
    id: 7,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 1,
  },
  {
    id: 8,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 2,
  },
  {
    id: 9,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 2,
  },
  {
    id: 10,
    agent: "Phillipe",
    assetId: "T-123",
    farmerName: "Steven Oyugi",
    farmerLat: 0.1242,
    farmerLong: 10.1123,
    area: 20,
    totalCost: 900,
    submittedAt: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    status: 1,
  },
];

export const KPIData = (kpiData) => {
  return [
    {
      id: 1,
      title: "Total upcoming sessions",
      count: kpiData?.upcoming || 0,
      image: <OperationsIcon size={16} className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 2,
      title: "Total ongoing sessions",
      count: kpiData?.ongoing || 0,
      image: <ClockIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 3,
      title: "Total completed sessions",
      count: kpiData?.completed || 0,
      total: kpiData?.total || 0,
      image: <CheckCircleIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
  ];
};

export const statusFilters = [
  {
    id: SESSION_STATUS.ONGOING,
    value: getSessionStatus(SESSION_STATUS.ONGOING),
  },
  {
    id: SESSION_STATUS.UPCOMING,
    value: getSessionStatus(SESSION_STATUS.UPCOMING),
  },
  {
    id: SESSION_STATUS.COMPLETED,
    value: getSessionStatus(SESSION_STATUS.COMPLETED),
  },
];

/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import useHeaderButtons from "./useHeaderButtons";
import Button from "../../../Core/Components/CustomButton";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { updateUsers } from "../../../Redux/Slices/users.slice";

const ButtonAndAddCTA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab");

  // check permission for adding a driver
  const hasDriverAddPermission = usePermission([PERMISSIONS.DRIVER_ADD]);

  // check permission for adding a farmer
  const hasFarmerAddPermission = usePermission([PERMISSIONS.PARTNER_ADD]);

  const headerButtons = useHeaderButtons().filter((item) => item.hasPermission);

  const onAdd = () => {
    if (selectedTab === "drivers") {
      navigate("/add-driver");
      return;
    } else {
      dispatch(
        updateUsers({
          key: "currentFarmer",
          value: {},
        }),
      );

      navigate("/add-partner");
      return;
    }
  };

  const onTabClick = (item) => {
    searchParams.set("tab", item.text.toLowerCase());
    setSearchParams(searchParams);
  };

  return (
    <div className="px-6 py-3.5 flex items-center justify-between border-b bg-background-white">
      <div className="flex items-center gap-x-6">
        {headerButtons.map((item) => {
          const selected = selectedTab === item.text.toLowerCase();

          return (
            <Button
              key={item.id}
              colors={selected ? "primary" : "white"}
              width={item.width}
              padding={item.padding}
              modifier={selectedTab !== item.id && "shadow"}
              onClick={() => onTabClick(item)}
            >
              <div>{item.text}</div>
            </Button>
          );
        })}
      </div>

      {hasDriverAddPermission && selectedTab === "drivers" && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={onAdd}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Driver
            </p>
          </div>
        </button>
      )}

      {hasFarmerAddPermission && selectedTab === "partners" && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={onAdd}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Partner
            </p>
          </div>
        </button>
      )}
    </div>
  );
};

export default ButtonAndAddCTA;

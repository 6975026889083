/* eslint-disable radix */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import BatteryIcon from "../../../Common/Svgs/BatteryIcon";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import {
  deleteBatteryV3,
  getBatteryV3,
  getKPI,
} from "../../../Redux/APIs/batteriesAPI";
import { BATTERY_STATUS } from "../Data";
import { CHARGER_STATUS } from "../../Charger/Data/constants";

const LOCAL_DEFAULT_LIMIT = 10;

const ongoingTripStatus = [
  BATTERY_STATUS.ON_A_TRIP,
  BATTERY_STATUS.DISPATCHED,
  BATTERY_STATUS.IN_TRANSIT,
];

const DeleteBatteryModal = ({
  visible,
  onCancel,
  batteryCurrentPage,
  setBatteryCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { currentBattery } = useSelector((state) => state.batteries);

  const canDelete = !(
    ongoingTripStatus.includes(+currentBattery.status) ||
    +currentBattery.chargingStatus === CHARGER_STATUS.CHARGING
  );

  const onConfirm = async () => {
    await dispatch(deleteBatteryV3({ batteryId: currentBattery.id }));

    const response = await dispatch(
      getBatteryV3({
        offset: ((batteryCurrentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && batteryCurrentPage > 1) {
      const previousPage = batteryCurrentPage - 1;
      setBatteryCurrentPage(previousPage);

      await dispatch(
        getBatteryV3({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    dispatch(getKPI());

    dispatch(
      updateBatteries({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Battery Deleted",
            description: `${
              currentBattery?.registrationNo || "1 battery"
            } was deleted sucessfully.`,
          },
        },
      }),
    );

    onCancel();
  };

  const getBody = () => {
    if (ongoingTripStatus.includes(+currentBattery.status)) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete battery
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The battery is currently on a trip or assigned to a request.
          </p>
        </div>
      );
    }

    if (+currentBattery.chargingStatus === CHARGER_STATUS.CHARGING) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete battery
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The battery is currently being charged.
          </p>
        </div>
      );
    }

    return (
      <div className="px-2 mt-5">
        <p className="text-base font-medium text-alert-red font-poppins">
          Delete battery
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          Are you sure you want to delete this battery from the system?
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          This action cannot be undone.
        </p>
      </div>
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<BatteryIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        {getBody()}

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {/* do not show the confirm button if battery is currently on trip or
          dispatch or being charged */}
          {canDelete ? (
            <Button colors="alert" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          ) : null}
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteBatteryModal;

import React from "react";
import { useNavigate } from "react-router-dom";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { TILLER_STATUS } from "./constants";
import getStatus from "./getStatus";
import CustomTooltip from "../../Core/Components/CustomTooltip";
import ListDropdown from "./ListDropdown";
import TillerIcon from "../../Common/Svgs/TillerIcon";
import UserIcon from "../../Common/Svgs/UserIcon";
import AddToServiceIcon from "../../Common/Svgs/AddToServiceIcon";
import BatteryPackColumn from "./BatteryPackColumn";

export const tillerColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Registration No.
      </div>
    ),
    dataIndex: "registrationNo",
    // width: "140px",
    sorter: (a, b) => a.registrationNo.localeCompare(b.registrationNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Model No.
      </div>
    ),
    dataIndex: "modelNo",
    sorter: (a, b) => a.modelNo.localeCompare(b.modelNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Asset Tag
      </div>
    ),
    sorter: (a, b) => a?.assetTag?.localeCompare(b?.assetTag),
    dataIndex: "assetTag",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a?.hubName?.localeCompare(b?.hubName),
    // width: "200px",
    render: (text) => (
      <div>
        <CustomTooltip tooltipTitle={text} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {/* {text.length > 15 ? `${text.substring(0, 15)}...` : text} */}
            {text}
          </div>
        </CustomTooltip>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Battery Pack
      </div>
    ),
    dataIndex: "batteryPack",
    // width: "150px",
    render: (text, record) => <BatteryPackColumn record={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Agent
      </div>
    ),
    dataIndex: "agentFirstName",
    sorter: (a, b) => a?.agentFirstName?.localeCompare(b?.agentLastName),
    // width: "140px",
    render: (text, record) => {
      const agentName = text
        ? `${record.agentFirstName} ${record.agentLastName}`
        : "--";

      return (
        <CustomTooltip tooltipTitle={agentName} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {agentName.length > 15
              ? `${agentName.substring(0, 15)}...`
              : agentName}
          </div>
        </CustomTooltip>
      );
    },
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       Usage
  //     </div>
  //   ),
  //   dataIndex: "usage",
  //   sorter: (a, b) => a?.usage?.localeCompare(b?.usage),
  //   // width: "140px",
  //   render: (text) => (
  //     <div>
  //       <CustomTooltip tooltipTitle={text} textColor="capitalize">
  //         <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
  //           {text.length > 15 ? `${text.substring(0, 15)}...` : text}
  //         </div>
  //       </CustomTooltip>
  //     </div>
  //   ),
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    // width: "170px",
    render: (text) => getStatus(text),
  },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const tillerData = [
  {
    id: 1,
    registrationNo: "T_65602R",
    modelNo: "12345",
    assetTag: "TGH2351",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 20,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.IN_SESSION,
  },
  {
    id: 2,
    registrationNo: "T_65612R",
    modelNo: "12345",
    assetTag: "TGH2352",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 20,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.HUB_STATION,
  },
  {
    id: 3,
    registrationNo: "T_65613R",
    modelNo: "12345",
    assetTag: "TGH2353",
    hubId: 33,
    hubName: "Magena Agr-E-Hub",
    percentage: 60,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.SERVICE,
  },
  {
    id: 4,
    registrationNo: "T_65614R",
    modelNo: "12345",
    assetTag: "TGH2354",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 92,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.HUB_STATION,
  },
  {
    id: 5,
    registrationNo: "T_65615R",
    modelNo: "12345",
    assetTag: "TGH2355",
    hubId: 33,
    hubName: "Magena Agr-E-Hub",
    percentage: 92,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.IN_SESSION,
  },
  {
    id: 6,
    registrationNo: "T_65616R",
    modelNo: "12345",
    assetTag: "TGH2356",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 22,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.IN_SESSION,
  },
  {
    id: 7,
    registrationNo: "T_656167",
    modelNo: "12345",
    assetTag: "TGH2357",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 88,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.HUB_STATION,
  },
  {
    id: 8,
    registrationNo: "T_65618R",
    modelNo: "12345",
    assetTag: "TGH2358",
    hubId: 33,
    hubName: "Magena Agr-E-Hub",
    percentage: 92,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.HUB_STATION,
  },
  {
    id: 9,
    registrationNo: "T_65619R",
    modelNo: "12345",
    assetTag: "TGH2359",
    hubId: 33,
    hubName: "Magena Agr-E-Hub",
    percentage: 66,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.IN_SESSION,
  },
  {
    id: 10,
    registrationNo: "T_656110R",
    modelNo: "12345",
    assetTag: "TGH23510",
    hubId: 33,
    hubName: "Kilgoris Agr-E-Hub",
    percentage: 66,
    agent: "Viola Toperesu",
    usage: "10h",
    status: TILLER_STATUS.HUB_STATION,
  },
];

export const TillerKPIData = (kpidata) => {
  const navigate = useNavigate();

  // service permission
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  return [
    {
      id: 1,
      title: "Total No. Of Tillers",
      count: kpidata?.total,

      redirect: false,
      image: <TillerIcon className="text-primary" />,
    },
    {
      id: 2,
      title: "No. Of Tillers In Sessions",
      count: kpidata?.inSession,
      redirect: false,
      image: <UserIcon size={16} className="stroke-primary fill-none" />,
    },
    {
      id: 3,
      title: "No. Of Tillers In Service",
      count: kpidata?.service,
      redirect: true,
      icon: (
        <img
          src="/assets/icons/chevron-down.svg"
          className="-rotate-90 "
          alt="maintenance"
        />
      ),
      onClick: () => {
        if (hasServicePermission && kpidata.service > 0) {
          navigate("/service");
        }
      },

      image: <AddToServiceIcon size={16} className="text-primary" />,
    },
  ];
};

export const statusFilters = [
  {
    id: TILLER_STATUS.IN_SESSION,
    value: getStatus(TILLER_STATUS.IN_SESSION),
  },
  {
    id: TILLER_STATUS.HUB_STATION,
    value: getStatus(TILLER_STATUS.HUB_STATION),
  },
  {
    id: TILLER_STATUS.SERVICE,
    value: getStatus(TILLER_STATUS.SERVICE),
  },
];

const getStepDetails = (number) => {
  if (number === 1) {
    return {
      title: "Schedule Session",
      description:
        "Enter farmer details, type of activity, cost and schedule the session as per the request received.",
    };
  }

  if (number === 2) {
    return {
      title: "Assign Asset & Agent",
      description: "Assign an asset and agent to carry out the session.",
    };
  }

  return {
    title: "Associate Batteries (optional)",
    description:
      "Assign the available batteries from your selected hub station.",
  };
};

export default getStepDetails;

/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomTextArea } from "../../../Core/Components/CustomInput";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { trikeParts } from "../Data";
import {
  addKnownIssues,
  resolveKnownIssue,
  updateKnownIssues,
} from "../../../Redux/APIs/trikesAPI";
import TrikeKnownIssueDropdown from "./TrikeKnownIssueDropdown";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import ToastMessageModal from "./ToastMessageModal";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const TrikeKnownIssues = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { currentTrike, checklist, showToastMessage } = useSelector(
    (state) => state.trikes,
  );

  // check if trike add permission exists
  const hasTrikeIssueAddPermission = usePermission([
    PERMISSIONS.TRIKE_ISSUE_ADD,
  ]);

  const checklistData = checklist.length
    ? checklist.map((item) => ({
        id: item.partId,
        value: item.partName,
      }))
    : trikeParts;

  const { handleSubmit, control, formState, setValue, clearErrors } = useForm({
    defaultValues: {
      issues: currentTrike.knownIssues.length
        ? currentTrike.knownIssues
        : [
            {
              partId: "",
              partName: "",
              partDescription: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "issues",
  });

  const rulesForPart = {
    required: "Required.",
  };

  const rulesForDescription = {
    required: "A clear description of the issue is required..",
  };

  const onCancel = () => navigate(-1);

  const onResolve = async (issue, index) => {
    await dispatch(
      resolveKnownIssue({
        trikeId: currentTrike.id,
        partId: issue.partId,
      }),
    );

    dispatch(
      updateTrikes({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Issue Resolved",
            description: `${issue.partName} issue for trike ${currentTrike.registrationNo} was resolved successfully.`,
          },
        },
      }),
    );

    remove(index);
  };

  const onSave = async (e) => {
    const payload = {
      trikeId: currentTrike.id,
      registrationNo: currentTrike.registrationNo,
      issues: e.issues,
    };

    if (currentTrike.knownIssues.length) {
      await dispatch(updateKnownIssues(payload));

      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Saved Changes",
              description: `Saved changes to issues for trike ${currentTrike.registrationNo} successfully.`,
            },
          },
        }),
      );
    } else {
      await dispatch(addKnownIssues(payload));

      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: `${e.issues?.length} Issue Added`,
              description: `${e.issues?.length} issues were added for Trike ${currentTrike.registrationNo} successfully.`,
            },
          },
        }),
      );
    }

    navigate(-1);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 3000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  return (
    <CustomLayout pageName="Trikes" nestedName={`${id}`} hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Add issues for this trike
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            {hasTrikeIssueAddPermission && (
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                onClick={handleSubmit(onSave)}
              >
                Save
              </Button>
            )}
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="bg-background-white h-[calc(100vh-88px-72px)] p-6 overflow-y-scroll">
        <p className="font-poppins text-xs font-medium">
          Choose a trike part to report issues
        </p>

        {fields.map((issue, index) => (
          <div key={issue.id} className="mt-6 max-w-[367px]">
            <div className="flex items-center justify-between">
              <p className="font-poppins text-xs font-medium">
                Issue {index + 1}
              </p>

              {hasTrikeIssueAddPermission && currentTrike.knownIssues.length ? (
                <TrikeKnownIssueDropdown
                  onResolve={() => onResolve(issue, index)}
                  onDelete={() => remove(index)}
                />
              ) : (
                hasTrikeIssueAddPermission &&
                fields.length > 1 && (
                  <button
                    type="button"
                    className="bg-alert-10 py-1 px-2 rounded-md"
                    onClick={() => remove(index)}
                  >
                    <TrashIcon className="text-alert-red" />
                  </button>
                )
              )}
            </div>

            <p className="font-aileron text-xs font-normal text-dark-gray mt-3 mb-1.5">
              Type
            </p>
            <Controller
              name={`issues.${index}.partName`}
              control={control}
              rules={rulesForPart}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  formState={formState}
                  enableSearch
                  searchPlaceholder="Search Trike Parts"
                  placeholder="Select A Trike Part"
                  options={checklistData}
                  onSelect={(option) => {
                    setValue(`issues.${index}.partName`, option.value);
                    setValue(`issues.${index}.partId`, option.id);
                    clearErrors(`issues.${index}.partName`);
                  }}
                  error={formState?.errors?.issues?.[index]?.partName?.message}
                  valueStyle={{
                    pointerEvents: hasTrikeIssueAddPermission ? "" : "none",
                  }}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
                formState?.errors?.issues?.[index]?.partName?.message
                  ? "text-alert-red visible"
                  : "invisible"
              }`}
            >
              {formState?.errors?.issues?.[index]?.partName?.message}
            </p>

            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
              Description
            </p>
            <Controller
              name={`issues.${index}.partDescription`}
              control={control}
              rules={rulesForDescription}
              render={({ field }) => (
                <CustomTextArea
                  {...field}
                  formState={formState}
                  className="w-[367px] bg-white rounded-md"
                  placeholder="Describe the issue..."
                  error={
                    formState?.errors?.issues?.[index]?.partDescription?.message
                  }
                  disabled={!hasTrikeIssueAddPermission}
                />
              )}
            />
          </div>
        ))}

        {hasTrikeIssueAddPermission && (
          <button
            type="button"
            className="py-1 px-2 mt-6"
            onClick={() => {
              append({
                item: "",
              });
            }}
          >
            <div className="flex items-center gap-x-2 ">
              <p className="font-poppins text-xs font-medium text-primary">
                Add More Issues
              </p>
              <PlusIcon className="w-4 h-4 stroke-primary" />
            </div>
          </button>
        )}
      </div>

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default TrikeKnownIssues;

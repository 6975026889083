import React from "react";

const TrendUpIcon = ({
  size = 20,
  containerColor = "#FAD152",
  arrowColor = "#085054",
}) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill={containerColor}
          d="M7.497 18.333h5c4.167 0 5.834-1.666 5.834-5.833v-5c0-4.167-1.667-5.833-5.834-5.833h-5c-4.166 0-5.833 1.666-5.833 5.833v5c0 4.167 1.667 5.833 5.833 5.833"
        />
        <path
          stroke={arrowColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m13.75 7.917-3.5 3.5-1.333-2-2.667 2.666"
        />
        <path
          stroke={arrowColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.078 7.917h1.667v1.666"
        />
      </svg>
    </span>
  );
};

export default TrendUpIcon;

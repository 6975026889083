import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import Button from "../../Core/Components/CustomButton";
import CustomSearchAndSelect from "../../Core/Components/CustomSearchAndSelect";
// import { getServiceDetails } from '../../Redux/APIs/servicesAPI';
import getUniqueList from "../../Utils/getUniqueList";
import {
  resetBatteries,
  updateBatteries,
} from "../../Redux/Slices/batteries.slice";
import { getBatterySwapsById } from "../../Redux/APIs/batteriesAPI";

const BatteryDetailsFilters = ({ setShowFilters }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { filtersForBatteryDetails, batterySwaps } = useSelector(
    (state) => state.batteries,
  );
  const [swapStationLocationList, setSwapStationLocationList] = useState([]);
  const [driverNameList, setDriverNameList] = useState([]);
  const [trikesList, setTrikesList] = useState([]);

  const uniqueDriverlist = getUniqueList(batterySwaps, "driver_name");
  const driverList = uniqueDriverlist.map((item) => {
    return { id: item.id, name: item.driver_name };
  });

  const uniqueSwapStationList = getUniqueList(batterySwaps, "swap_station");
  const swapStationList = uniqueSwapStationList.map((item) => {
    return { id: item.id, name: item.swap_station };
  });

  const uniqueTrikeIdList = getUniqueList(batterySwaps, "trike_id");
  const trikeIdList = uniqueTrikeIdList.map((item) => {
    return { id: item.id, name: item.trike_id };
  });

  const onCancel = () => {
    setShowFilters(false);
  };

  const hasFieldValues = () => {
    return (
      filtersForBatteryDetails?.swapStations?.length > 0 ||
      filtersForBatteryDetails?.drivers?.length > 0 ||
      filtersForBatteryDetails?.trikeIds?.length > 0 ||
      (filtersForBatteryDetails?.dateRangeStart !== "" &&
        filtersForBatteryDetails?.dateRangeEnd !== "")
    );
  };

  const handleDateRangeChange = (e) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      const startTimeCheck = e[0].format("YYYY-MM-DD");
      const endTimeCheck = e[1].format("YYYY-MM-DD");

      dispatch(
        updateBatteries({
          key: "filtersForBatteryDetails",
          value: {
            ...filtersForBatteryDetails,
            dateRangeStart: startTimeCheck,
            dateRangeEnd: endTimeCheck,
          },
        }),
      );
    }
  };

  const handleApplyFilters = () => {
    dispatch(
      getBatterySwapsById({
        batteryId: id,
        startTime: filtersForBatteryDetails?.dateRangeStart,
        endTime: filtersForBatteryDetails?.dateRangeEnd,
        swapStations: filtersForBatteryDetails?.swapStations,
        drivers: filtersForBatteryDetails?.drivers,
        trikeIds: filtersForBatteryDetails?.trikeIds,
      }),
    );
    onCancel();
  };

  useEffect(() => {
    // setFinalList(filtersForBatteryDetails?.addedBy);
  }, [filtersForBatteryDetails]);

  const handleClearAll = async () => {
    await dispatch(getBatterySwapsById({ batteryId: id }));
    setSwapStationLocationList([]);
    setDriverNameList([]);
    setTrikesList([]);

    dispatch(resetBatteries(["filtersForBatteryDetails"]));
  };

  return (
    <div className="absolute z-[500] h-[400px] p-3 bg-white rounded-md top-[-120px] right-[-140px] overflow-y-scroll w-78 shadow-moreFilters">
      <div className="flex items-center justify-between">
        <div className="text-base font-medium text-new-black font-poppins">
          More filters
        </div>
        <CloseIcon
          className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
          onClick={onCancel}
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Start Date-End Date
      </div>
      <DatePicker.RangePicker
        value={[
          filtersForBatteryDetails?.dateRangeStart !== "" &&
            dayjs(filtersForBatteryDetails?.dateRangeStart),
          filtersForBatteryDetails?.dateRangeEnd !== "" &&
            dayjs(filtersForBatteryDetails?.dateRangeEnd),
        ]}
        format="DD MMM YYYY"
        separator={<div className="text-dark-grey-text"> - </div>}
        onCalendarChange={(e) => handleDateRangeChange(e)}
        className="h-[39px] mt-2"
      />
      <div className="mt-6">
        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
          Swap Station
        </div>
        <CustomSearchAndSelect
          placeholder="Select Swap Station"
          inputList={swapStationList}
          finalList={swapStationLocationList}
          setFinalList={setSwapStationLocationList}
          onChange={(e) =>
            dispatch(
              updateBatteries({
                key: "filtersForBatteryDetails",
                value: { ...filtersForBatteryDetails, swapStations: e },
              }),
            )
          }
        />

        <div className="font-light mt-6 font-poppins text-xs leading-4.5 text-dark-grey-text">
          Drivers
        </div>
        <CustomSearchAndSelect
          placeholder="Select Driver"
          inputList={driverList}
          finalList={driverNameList}
          setFinalList={setDriverNameList}
          onChange={(e) =>
            dispatch(
              updateBatteries({
                key: "filtersForBatteryDetails",
                value: { ...filtersForBatteryDetails, drivers: e },
              }),
            )
          }
        />

        <div className="font-light mt-6 font-poppins text-xs leading-4.5 text-dark-grey-text">
          Trike
        </div>
        <CustomSearchAndSelect
          placeholder="Select Trike ID"
          inputList={trikeIdList}
          finalList={trikesList}
          setFinalList={setTrikesList}
          onChange={(e) =>
            dispatch(
              updateBatteries({
                key: "filtersForBatteryDetails",
                value: { ...filtersForBatteryDetails, trikeIds: e },
              }),
            )
          }
        />
      </div>

      <div className="flex mt-6 gap-x-3">
        <Button
          size="primary"
          width="save"
          colors="grey"
          modifier={hasFieldValues() ? null : "blur"}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <Button
          size="primary"
          width="save"
          type="submit"
          modifier={hasFieldValues() ? null : "blur"}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

export default BatteryDetailsFilters;

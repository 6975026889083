import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../Core/Components/CustomInput";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import FilterItem from "../../Core/Components/FilterItem";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { statusFilters } from "./data";

const TillerHeader = ({
  showKpi,
  setShowKpi,
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const { allHubs } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allHubs.map((hub) => ({
    id: hub?.id,
    value: hub?.name,
  }));

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white px-6 py-2.5 border-t-[0.5px] border-light-grey flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search Tillers"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />

        <FilterItem
          label="Hub Station"
          identifier="hub"
          contentData={hubFilters}
          onSelect={onSelect}
          selectedValues={filters.hub}
          className="max-h-[200px] overflow-y-scroll"
          enableSearch
        />

        <FilterItem
          label="Status"
          identifier="status"
          className="max-h-max"
          customValue
          contentData={statusFilters}
          onSelect={onSelect}
          selectedValues={filters.status}
        />
      </div>

      <div className="flex items-center gap-x-3">
        <button
          type="button"
          className="flex items-center justify-center w-8 h-6 rounded-md bg-[#eeeeee]"
          onClick={() => setShowKpi(!showKpi)}
        >
          <ChevronIcon
            className={`w-4 h-4 stroke-black ${
              showKpi ? "rotate-90" : "-rotate-90"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default TillerHeader;

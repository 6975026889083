const getServiceAssetType = (type) => {
  switch (type) {
    case 1:
      return "Trike";
    case 2:
      return "Battery";
    case 3:
      return "Tiller";
    default:
      return "";
  }
};

export default getServiceAssetType;

/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  addDriverV3,
  addFarmerV3,
  deleteDriverV3,
  deleteFarmerV3,
  editDriverV3,
  editFarmerV3,
  getAllDrivers,
  getDriversV3,
  getFarmerDetails,
  getFarmersFromODK,
  getFarmersV3,
  getFilterValues,
  getUserDetails,
  getUsers,
  getUsersFromODK,
  suspendOrResumeDriverV3,
} from "../APIs/usersAPI";

const initialState = {
  loading: false,
  usersList: [],
  farmersList: [],
  tripDetails: [],

  // phase 3
  driversListV3: {
    result: [],
    totalRecords: 0,
  },
  allDrivers: [],
  farmersListV3: {
    result: [],
    totalRecords: 0,
  },
  selectedTab: 1,
  selectedFarmerRowIds: [],
  currentDriver: [],
  currentFarmer: [],
  tripDetailsDrawer: {
    visible: false,
    driverName: "",
  },
  deleteDriverModal: {
    visible: false,
    id: 0,
    name: "",
  },
  deleteFarmerModal: {
    visible: false,
    id: 0,
    name: "",
  },
  showToastMessage: {
    visible: false,
    data: {},
  },

  selectedFarmersForExport: [],
  exportFarmerModal: false,
  driverThreeDotsPopoverId: 0,
  farmerThreeDotsPopoverId: 0,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetUsers: (state, action) => {
      action.payload.map((item) => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const responseData = action.payload.map((item) => ({
        id: item.id,
        name: item.name,
        type: null,
        userCategory: item.user_type,
        licenseId: item.license_id,
        submittedBy: item.submitted_by,
        submittedAt: item.submitted_at,
        trikeId: item.trike_id || "--",
        noOfTrips: item.no_of_trips,
      }));

      state.usersList = responseData;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get farmers from DB
    builder.addCase(getFarmerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFarmerDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.farmersList = action.payload;
    });
    builder.addCase(getFarmerDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get farmers from ODK

    builder.addCase(getFarmersFromODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFarmersFromODK.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(getFarmersFromODK.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get users from trike

    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get filters values
    builder.addCase(getFilterValues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilterValues.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const { trikes, addedBy } = action.payload;

      state.trikesFilters = trikes;
      state.addedByFilters = addedBy;
    });
    builder.addCase(getFilterValues.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get users from odk

    builder.addCase(getUsersFromODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersFromODK.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(getUsersFromODK.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // phase3

    // get drivers
    builder.addCase(getDriversV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDriversV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.driversListV3 = action.payload;
    });
    builder.addCase(getDriversV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all drivers
    builder.addCase(getAllDrivers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDrivers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allDrivers = action.payload.result;
    });
    builder.addCase(getAllDrivers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add driver
    builder.addCase(addDriverV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDriverV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addDriverV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit driver
    builder.addCase(editDriverV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editDriverV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editDriverV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete driver
    builder.addCase(deleteDriverV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDriverV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteDriverV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // suspend or resume driver

    builder.addCase(suspendOrResumeDriverV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(suspendOrResumeDriverV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(suspendOrResumeDriverV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get farmers
    builder.addCase(getFarmersV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFarmersV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.farmersListV3 = action.payload;
    });
    builder.addCase(getFarmersV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add farmer
    builder.addCase(addFarmerV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addFarmerV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addFarmerV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit farmer
    builder.addCase(editFarmerV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editFarmerV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editFarmerV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete farmer
    builder.addCase(deleteFarmerV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFarmerV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteFarmerV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const { updateUsers, resetUsers } = usersSlice.actions;

export default usersSlice.reducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import HomeItem from "./HomeItem";
import EnergyResourcesItem from "./EnergyResourcesItem";
import AssetsItem from "./AssetsItem";
import ManagementCentreItem from "./ManagementCentreItem";
import SettingsItem from "./SettingsItem";
import LoggedUserItem from "./LoggedUserItem";
import FinanceItem from "./FinanceItem";
import { SUBMENU } from "../data/contants";
import ArrowIcon from "../../../Common/Svgs/ArrowIcon";
import OperationItem from "./OperationItem";

const Sidebar = ({ pageName }) => {
  const { companyLogo } = useSelector((state) => state.loggedInUser);

  const [sidebarActive, setSidebarActive] = useState(false);
  const [subMenu, setSubMenu] = useState(SUBMENU.DEFAULT);

  const handleMenuClick = () => {
    setSidebarActive((prev) => !prev);
  };

  return (
    <aside
      className="absolute z-[99999] h-screen pt-6 flex flex-col bg-primary border-r border-light-grey transition-all duration-200"
      style={{
        width: sidebarActive ? "232px" : "72px",
      }}
      // onMouseEnter={() =>
      //   setTimeout(() => {
      //     setSidebarActive(true);
      //   }, 200)
      // }
      // onMouseLeave={() =>
      //   setTimeout(() => {
      //     setSidebarActive(false);
      //   }, 200)
      // }
    >
      <div className="flex items-center justify-between px-5">
        <div
          className="w-full"
          role="button"
          onClick={() => {
            if (!sidebarActive) {
              handleMenuClick();
            }
          }}
        >
          <img
            src={sidebarActive ? companyLogo : "/assets/menu.png"}
            className="w-8 h-8 shrink-0"
            alt="Songa Mobility"
          />
        </div>

        {sidebarActive && (
          <button
            type="button"
            className="-rotate-90"
            onClick={() => setSidebarActive(false)}
          >
            <ArrowIcon size={20} className="text-sidebar-base" />
          </button>
        )}
      </div>

      <div className="w-full">
        <HomeItem sidebarActive={sidebarActive} pageName={pageName} />

        <AssetsItem sidebarActive={sidebarActive} pageName={pageName} />

        <OperationItem
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <EnergyResourcesItem
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <ManagementCentreItem
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <div className="mt-4 bg-primary-50 w-full h-[1px]" />

        <FinanceItem
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <SettingsItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
        />

        <LoggedUserItem
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
      </div>
    </aside>
  );
};

export default Sidebar;

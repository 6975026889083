import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import OperationsIcon from "../../../Common/Svgs/OperationsIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import {
  useAgentCardData,
  useAssetCardData,
  useSessionCardData,
  useSessionSummaryData,
} from "./useCardData";
import SessionSummaryIcon from "../../../Common/Svgs/SessionSummaryIcon";
import { SESSION_STATUS } from "../constants";
import TrendUpIcon from "../../../Common/Svgs/TrendUpIcon";
import TrendDownIcon from "../../../Common/Svgs/TrendDownIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";

const ListIcon = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0V7C1 9.20914 2.79086 11 5 11H16" stroke="#528587" />
    </svg>
  );
};

const CARD_DATA = {
  ESD: {
    icon: <SessionSummaryIcon className="text-white" size={20} />,
    breakPoint: 6,
  },

  ASD: {
    icon: <OperationsIcon className="text-white" size={20} />,
    breakPoint: 3,
  },

  AGD: {
    icon: (
      <UserIcon
        className="stroke-white fill-none"
        size={20}
        strokeWidth={1.85}
      />
    ),
    breakPoint: 3,
  },
};

const Card = ({
  height = "360px",
  title = "",
  content = [],
  uniqueKey = "",
}) => {
  const { icon, breakPoint } = CARD_DATA[uniqueKey];

  return (
    <div
      className="flex-1 bg-background-white border border-light-grey rounded-2xl px-4 py-6"
      style={{
        height,
      }}
    >
      <div className="flex items-center gap-x-2.5 pb-4 border-b border-light-grey">
        <CustomRoundedImage background="bg-primary" image={icon} />
        <p className="font-poppins font-medium text-base capitalize">{title}</p>
      </div>

      <div className="mt-2">
        {content?.slice(0, breakPoint)?.map((item) => (
          <div
            key={item.title}
            className="pt-2 flex items-center justify-between"
          >
            <p className="font-aileron font-normal text-sm leading-[16.8px] text-grey-200 capitalize">
              {item.title}
            </p>

            <div
              role={item?.onClick && "button"}
              className="flex items-center gap-x-2"
              onClick={() => {
                if (item?.onClick) {
                  item.onClick();
                }
                return null;
              }}
            >
              <p
                className={`font-aileron font-semibold text-sm leading-[16.8px] ${
                  item?.onClick ? "cursor-pointer" : ""
                }`}
              >
                {item.value}
              </p>

              {item?.onClick ? (
                <ChevronIcon className="w-4 h-4 stroke-dark-grey-text" />
              ) : null}
            </div>
          </div>
        ))}
      </div>

      {content.length > breakPoint ? (
        <>
          <div className="w-full h-[1px] bg-light-grey mt-4 mb-2" />

          {content?.slice(breakPoint).map((item) => (
            <div
              key={item.title}
              className="pt-2 flex items-center justify-between"
            >
              <p className="font-aileron font-normal text-sm leading-[16.8px] text-grey-200 capitalize">
                {item.title}
              </p>

              <p className="font-aileron font-semibold text-sm leading-[16.8px]">
                {item.value}
              </p>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

const CompletedCard = ({
  content = [],
  farmerFeedback = "",
  agentConcern = "",
}) => {
  const getTrend = (item) => {
    if (item?.trend) {
      if (item.trend === "up") return <TrendUpIcon />;
      if (item.trend === "down") return <TrendDownIcon />;
    }

    return null;
  };

  return (
    <div className="bg-primary rounded-2xl px-4 py-6 border border-light-grey mb-4">
      <div className="flex items-center gap-x-2.5 pb-4 border-b-[0.5px] border-[#528587]">
        <CustomRoundedImage
          background="bg-background-white"
          image={<SessionSummaryIcon className="text-black" size={20} />}
        />
        <p className="font-poppins font-medium text-background-white text-base capitalize">
          Session Summary
        </p>
      </div>

      <div className="pt-2">
        {content.map((item) => (
          <div
            key={item.title}
            className="mt-2 flex items-center justify-between"
          >
            <p className="font-aileron font-normal text-sm leading-[16.8px] text-background capitalize">
              {item.title}
            </p>

            <div className="flex items-center gap-x-2">
              <p className="font-aileron font-semibold text-sm leading-[16.8px] text-background">
                {item.value}
              </p>

              {getTrend(item)}
            </div>
          </div>
        ))}
      </div>

      <div className="w-full h-[0.5px] bg-[#528587] my-4" />

      {farmerFeedback || agentConcern ? (
        <div className="bg-yellow-10 rounded-md px-4 py-2">
          <p className="font-aileron font-semibold text-xs leading-[14.4px] text-primary pb-2 border-b-[0.5px] border-[#528587]">
            Additional Information
          </p>

          {farmerFeedback ? (
            <>
              <p className="mt-2 font-aileron font-semibold text-xs leading-[14.4px]">
                Farmer’s Feedback
              </p>

              <div className="mt-2 flex items-center gap-x-1.5">
                <ListIcon />

                <p className="font-aileron font-normal text-dark-gray text-xs leading-[14.4px]">
                  {farmerFeedback}
                </p>
              </div>
            </>
          ) : null}

          {agentConcern ? (
            <>
              <p className="mt-2 font-aileron font-semibold text-xs leading-[14.4px]">
                Agent’s Concerns
              </p>

              <div className="mt-2 flex items-center gap-x-1.5">
                <ListIcon />

                <p className="font-aileron font-normal text-dark-gray text-xs leading-[14.4px]">
                  {agentConcern}
                </p>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const OverviewPart = () => {
  const { currentSession } = useSelector((state) => state.sessions);

  const summaryContent = useSessionSummaryData();

  return (
    <div className="p-6 bg-background h-[calc(100vh-72px-59px)] overflow-y-scroll">
      {currentSession?.status === SESSION_STATUS.COMPLETED && (
        <CompletedCard
          content={summaryContent}
          farmerFeedback={currentSession?.feedback}
          agentConcern={currentSession?.remark}
        />
      )}

      <div className="flex items-center gap-x-4 mb-4">
        <Card
          title="Estimated Session Details"
          uniqueKey="ESD"
          content={useSessionCardData()}
        />
        <Card
          title="Asset Details"
          uniqueKey="ASD"
          content={useAssetCardData()}
        />
      </div>

      <div className="w-[49%]">
        <Card
          title="Agent Details"
          uniqueKey="AGD"
          height="100%"
          content={useAgentCardData()}
        />
      </div>
    </div>
  );
};

export default OverviewPart;

/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import CalendarIcon from "../../../Common/Svgs/CalendarIcon";
import { activityType, timeSlots } from "./constants";
import { useFarmersOnAdd, useFarmsForFarmer } from "./useOnAdd";
import { updateSession } from "../../../Redux/Slices/sessions.slice";

const LabelAndInput = ({
  intent = "createUser",
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
  className = "",
  disabled = false,
}) => {
  return (
    <div
      className={className}
      onClick={() => console.log("clicking continuously")}
    >
      <CustomInputWithController
        intent={intent}
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
        isFieldDisabled={disabled}
      />
    </div>
  );
};

const Step1 = ({ control, formState, setValue, clearErrors, watch }) => {
  const dispatch = useDispatch();

  const localActivityDate = watch("activityDate");

  const [focus, setFocus] = useState({
    lat: false,
    long: false,
    area: false,
    estimatedCostPerSqM: false,
  });

  // farmers list for dropdown
  const farmerList = useFarmersOnAdd();
  const farmsList = useFarmsForFarmer(watch("farmerId"));

  // errors
  const farmerNameError = formState?.errors?.farmerName?.message;
  const dateError = formState?.errors?.activityDate?.message;
  const startTimeError = formState?.errors?.startTime?.message;

  const localFarmLocationName = watch("farmLocationName");
  const localFarmLat = watch("farmLat");
  const localFarmLong = watch("farmLong");

  const handleLatLongClick = (field) => {
    if (localFarmLocationName) {
      dispatch(
        updateSession({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: `Cannot add new ${field}`,
              description: "Farm location already selected.",
              isError: true,
            },
          },
        }),
      );
    }
  };

  const getTotalCost = () => {
    const localArea = watch("area");
    const localEstimation = watch("estimatedCostPerSqM");

    if (!localArea || !localEstimation) return "";

    const totalCost = (localArea * localEstimation).toFixed(2);
    setValue("totalCost", totalCost);
    return totalCost;
  };

  return (
    <div className="bg-background-white p-6 h-[calc(100vh-72px-54px-90px)] overflow-y-scroll flex flex-col gap-y-6">
      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Who is requesting a session?
        </p>

        <Controller
          name="farmerName"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Farmer"
              placeholder="Farmer's Name"
              formState={formState}
              options={farmerList}
              onSelect={(data) => {
                setValue("farmerId", data.id);
                setValue("farmerName", data.value);
                clearErrors("farmerName");
                setValue("farmLocationId", null);
                setValue("farmLocationName", "");
              }}
              className="max-h-[200px] overflow-y-scroll"
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            farmerNameError ? "mt-2 text-alert-red visible" : "invisible"
          }`}
        >
          {farmerNameError}
        </p>
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Select Farm Location
        </p>

        <Controller
          name="farmLocationName"
          control={control}
          rules={{
            required: localFarmLat && localFarmLong ? false : "Required.",
          }}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Farm Location"
              placeholder="Farm Location"
              formState={formState}
              options={farmsList}
              onSelect={(data) => {
                setValue("farmLocationId", data.id);
                setValue("farmLocationName", data.value);
                clearErrors("farmLocationName");
                clearErrors("farmLat");
                clearErrors("farmLong");
              }}
              className="max-h-[200px] overflow-y-scroll"
              onRemove={() => {
                setValue("farmLocationId", null);
                setValue("farmLocationName", "");
                clearErrors("farmLocationName");
                clearErrors("farmLat");
                clearErrors("farmLong");
              }}
            />
          )}
        />
      </div>

      <div className="w-[367px] text-center font-aileron font-normal text-xs leading-[14.4px]">
        Or
      </div>

      <div className="w-[367px]">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Enter Latitude & Longitude of the Location
        </p>

        <div className="flex items-start gap-x-2">
          {localFarmLocationName ? (
            <div
              className="px-3.5 h-[39px] w-full flex-1 flex items-center bg-background border border-light-grey rounded-md cursor-not-allowed"
              onClick={() => handleLatLongClick("Latitude")}
            >
              <p className="font-aileron font-normal text-xs text-dark-gray">
                Latitude
              </p>
            </div>
          ) : (
            <LabelAndInput
              intent="primary"
              control={control}
              formState={formState}
              name="farmLat"
              placeholder="Latitude"
              rules={{
                required: localFarmLocationName ? false : "Required.",
              }}
              focus={focus.lat}
              setFocus={(state) => setFocus({ ...focus, lat: state })}
              disabled={localFarmLocationName}
              className="relative z-20"
            />
          )}

          {localFarmLocationName ? (
            <div
              className="px-3.5 h-[39px] w-full flex-1 flex items-center bg-background border border-light-grey rounded-md cursor-not-allowed"
              onClick={() => handleLatLongClick("Longitude")}
            >
              <p className="font-aileron font-normal text-xs text-dark-gray">
                Longitude
              </p>
            </div>
          ) : (
            <LabelAndInput
              intent="primary"
              control={control}
              formState={formState}
              name="farmLong"
              placeholder="Longitude"
              rules={{
                required: localFarmLocationName ? false : "Required.",
              }}
              focus={focus.long}
              setFocus={(state) => setFocus({ ...focus, long: state })}
              disabled={localFarmLocationName}
            />
          )}
        </div>
      </div>

      <div className="w-[367px] relative">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Estimated Area
        </p>

        <div className="flex items-center gap-x-2">
          <LabelAndInput
            control={control}
            formState={formState}
            name="area"
            placeholder="Enter Area"
            rules={{
              required: "Required.",
              validate: {
                isNumber: (value) => {
                  return value > 0 || "Please enter valid number";
                },
              },
            }}
            focus={focus.area}
            setFocus={(state) => setFocus({ ...focus, area: state })}
          />

          <p className="font-aileron text-xs font-normal absolute -right-10 top-9">
            Sq.M
          </p>
        </div>
      </div>

      <div className="w-[367px] relative">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Estimated Cost per Sq.M Area
        </p>

        <div className="flex items-center gap-x-2">
          <LabelAndInput
            control={control}
            formState={formState}
            name="estimatedCostPerSqM"
            placeholder="Enter Amount"
            rules={{
              required: "Required.",
              validate: {
                isNumber: (value) => {
                  return value > 0 || "Please enter valid number";
                },
              },
            }}
            focus={focus.estimatedCostPerSqM}
            setFocus={(state) =>
              setFocus({ ...focus, estimatedCostPerSqM: state })
            }
          />

          <p className="font-aileron text-xs font-normal absolute -right-10 top-9">
            KSh
          </p>
        </div>
      </div>

      <div className="w-[367px] relative">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Total Estimated Cost
        </p>

        <div className="flex items-center gap-x-2">
          <div className="px-3.5 py-2.25 h-10 w-full bg-background border border-light-grey rounded-md cursor-not-allowed">
            <p className="font-aileron font-normal text-xs">{getTotalCost()}</p>
          </div>

          <p className="font-aileron text-xs font-normal absolute -right-10 top-9">
            KSh
          </p>
        </div>
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Type of Activity
        </p>

        <Controller
          name="activityName"
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              placeholder="Farm Location"
              formState={formState}
              options={activityType}
              onSelect={(data) => {
                setValue("activityId", data.id);
                setValue("activityName", data.value);
              }}
              className="max-h-[200px] overflow-y-scroll"
            />
          )}
        />
      </div>

      <div className="">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Date
        </p>

        <Controller
          name="activityDate"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              className={`font-aileron text-dark-gray font-semibold text-xs h-10 w-[367px] ${
                dateError && "border border-alert-red hover:border-alert-red"
              }`}
              value={localActivityDate ? dayjs(localActivityDate) : null}
              placeholder="Select Date"
              format="MMM DD, YYYY"
              disabledDate={(current) =>
                current.isBefore(dayjs().subtract(1, "day"))
              }
              suffixIcon={
                dateError ? (
                  <AlertIcon className="text-alert-red" />
                ) : (
                  <CalendarIcon />
                )
              }
              allowClear={false}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            dateError ? "text-alert-red visible mt-2" : "invisible"
          }`}
        >
          {dateError}
        </p>
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Start Time
        </p>

        <Controller
          name="startTime"
          control={control}
          rules={{ required: "Required." }}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              placeholder="Select Time"
              formState={formState}
              options={timeSlots}
              onSelect={(data) => {
                setValue("startTime", data.value);
                clearErrors("startTime");
              }}
              className="max-h-[200px] overflow-y-scroll"
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            startTimeError ? "mt-2 text-alert-red visible" : "invisible"
          }`}
        >
          {startTimeError}
        </p>
      </div>
    </div>
  );
};

export default Step1;

/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateHome } from "../../../Redux/Slices/home.slice";
import { SESSION_STATUS } from "../../../Modules/Sessions/constants";
import { getSessionById } from "../../../Redux/APIs/sessionsAPI";
import { updateSession } from "../../../Redux/Slices/sessions.slice";

const ItemBody9 = ({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const noRedirection = [SESSION_STATUS.ONGOING, SESSION_STATUS.COMPLETED];

  const handleClick = async () => {
    const sessionId = notification?.metaData?.sessionId;

    if (!sessionId) return;

    try {
      dispatch(updateHome({ key: "notificationPopupVisible", value: false }));

      const response = await dispatch(
        getSessionById({
          sessionId: +sessionId,
        }),
      );

      const { status } = response.payload.session;

      if (noRedirection.includes(+status)) return;

      dispatch(
        updateSession({
          key: "createSessionStep",
          value: 3,
        }),
      );

      navigate(`/sessions/edit-session/${sessionId}`);
    } catch (err) {
      console.log("Failed to fetch session details:", err);
    }
  };

  return (
    <div>
      <p className="font-poppins text-xs font-light text-black">
        Please assign batteries to session id{" "}
        <span className="font-medium">{notification?.metaData?.sessionId}</span>{" "}
        starting at{" "}
        <span className="font-medium">{notification?.metaData?.timeSlot} </span>
        from{" "}
        <span className="capitalize">{notification?.metaData?.hubName}</span>
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="bg-primary hover:bg-primary-50 py-2 px-4 rounded-md w-full"
          onClick={handleClick}
        >
          <p className="font-poppins text-xs font-medium text-background-white text-center">
            Assign Batteries
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody9;

/* eslint-disable no-console */
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Col, Row } from "antd";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { INITIAL_ZOOM_LEVEL, MAX_BOUNDS } from "../../Home/Data/HomeData";
import getOngoingSessionIcon from "./getOngoingSessionIcon";
import ZoomButton, {
  ExpandButton,
  RelocateButton,
} from "../../../Core/Components/MapMarkerButtons";
import getSessionStatus from "../getSessionStatus";
import { SESSION_STATUS } from "../constants";
import SessionFullScreenMap from "./SessionFullScreenMap";
import OverviewPart from "./OverviewPart";
import SessionChecklistModal from "./SessionChecklistModal";
import { updateSession } from "../../../Redux/Slices/sessions.slice";
import ListDropdown from "../ListDropdown";

const SessionDetailScreen = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentSession, checklistModal } = useSelector(
    (state) => state.sessions,
  );

  const [fullScreen, setFullScreen] = useState(false);

  const mapRef = useRef(null);

  const getAssetLatLngs = () => {
    if (
      currentSession?.status === SESSION_STATUS.UPCOMING ||
      currentSession?.status === SESSION_STATUS.COMPLETED
    ) {
      return [-0.6803741, 34.7569482];
    }

    if (currentSession?.farmLat && currentSession?.farmLong) {
      return [currentSession.farmLat, currentSession.farmLong];
    }

    return [-0.6803741, 34.7569482];
  };

  return (
    <CustomLayout pageName="Sessions" nestedName={`${id}`}>
      <Row className="w-full h-full">
        <Col span={16}>
          <div className="w-full bg-background-white py-4 px-6 border-b border-light-grey">
            <div className="flex items-center justify-between">
              <p className="text-base font-medium font-poppins">Overview</p>

              <div className="flex items-center gap-x-4">
                <div>{getSessionStatus(currentSession?.status)}</div>

                {currentSession?.status === SESSION_STATUS.UPCOMING ? (
                  <div
                    role="button"
                    className="w-8 h-6 rounded-md flex items-center justify-center bg-light-grey hover:bg-background-grey-hover"
                  >
                    <ListDropdown detail={currentSession} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <OverviewPart />
        </Col>

        <Col span={8}>
          <div className="bg-background-white py-4 px-6 border-l border-light-grey">
            <p className="font-poppins font-medium text-base">Location</p>
          </div>

          <div className="border-l border-light-grey relative">
            <div className="absolute right-6 top-6 z-30">
              <ExpandButton onClick={() => setFullScreen(true)} />
            </div>

            <div className="h-[calc(100vh-128px)]">
              <MapContainer
                center={getAssetLatLngs()}
                zoom={INITIAL_ZOOM_LEVEL}
                maxBounds={MAX_BOUNDS}
                className="z-10 w-full h-full"
                zoomControl={false}
                ref={mapRef}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {currentSession?.status === SESSION_STATUS.ONGOING ? (
                  <Marker
                    position={getAssetLatLngs()}
                    icon={getOngoingSessionIcon()}
                  />
                ) : null}
              </MapContainer>
            </div>

            <div className="absolute bottom-6 right-6 z-30">
              <div className="flex flex-col gap-y-3">
                <RelocateButton
                  mapRef={mapRef}
                  initialZoomlevel={INITIAL_ZOOM_LEVEL}
                  center={getAssetLatLngs()}
                />
                <ZoomButton mapRef={mapRef} />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <SessionFullScreenMap
        visible={fullScreen}
        onCancel={() => setFullScreen(false)}
      />

      <SessionChecklistModal
        visible={checklistModal}
        onCancel={() =>
          dispatch(
            updateSession({
              key: "checklistModal",
              value: false,
            }),
          )
        }
      />
    </CustomLayout>
  );
};

export default SessionDetailScreen;

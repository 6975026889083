import React from "react";

const TrendDownIcon = ({
  size = 20,
  containerColor = "#FAD152",
  arrowColor = "#085054",
}) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill={containerColor}
          stroke={containerColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.497 18.333h5c4.167 0 5.834-1.666 5.834-5.833v-5c0-4.167-1.667-5.833-5.834-5.833h-5c-4.166 0-5.833 1.666-5.833 5.833v5c0 4.167 1.667 5.833 5.833 5.833"
        />
        <path
          stroke={arrowColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m13.75 12.083-3.5-3.5-1.333 2L6.25 7.917"
        />
        <path
          stroke={arrowColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.078 12.083h1.667v-1.666"
        />
      </svg>
    </span>
  );
};

export default TrendDownIcon;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import useThreeDotsOptions from "./useThreeDotsOptions";
import { updateSession } from "../../Redux/Slices/sessions.slice";
import { SESSION_STATUS } from "./constants";

const ListDropdown = ({ detail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.sessions);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const getItems = () => {
    if (detail.status === SESSION_STATUS.UPCOMING) {
      return threeDotsOptions;
    }

    return [];
  };

  const onOptionClick = async (optionId) => {
    dispatch(
      updateSession({
        key: "currentSession",
        value: detail,
      }),
    );

    setOpen(false);

    switch (optionId) {
      case 1:
        navigate(`/sessions/edit-session/${detail.id}`);
        break;
      case 2:
        // redirect to the final step of associating batteries
        dispatch(
          updateSession({
            key: "createSessionStep",
            value: 3,
          }),
        );

        dispatch(
          updateSession({
            key: "associateBatteries",
            value: detail?.batteries?.length ? detail.batteries : [],
          }),
        );

        navigate(`/sessions/edit-session/${detail.id}`);
        break;
      case 3:
        dispatch(
          updateSession({
            key: "deleteSessionModal",
            value: true,
          }),
        );
        break;

      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateSession({
        key: "threeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!getItems().length) {
    return <div />;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (detail?.hubId && !userDetails.hubs.includes(+detail.hubId)) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === detail.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {getItems().map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group 
              ${
                option.id === 3
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize whitespace-nowrap ${
                  option.id === 3
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;

/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const getBatteryDetails = createAsyncThunk(
  "batteries/getBatteryDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/things/details`,
        {
          params,
        },
      );
      // console.log(response.data, 'Hello');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBatteryDetailsODK = createAsyncThunk(
  "batteries/getBatteryDetailsODK",
  async (params, { rejectWithValue, getState }) => {
    try {
      let trikesState = {};
      let batteryDetailsFromTB = {};

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/battery-details`,
        {
          params,
        },
      );

      if (getState !== undefined) {
        const state = getState();
        trikesState = state?.trikes?.trikeDetails;
        batteryDetailsFromTB = state?.batteries?.batteryDetails;
      }

      return { data: response.data, trikesState, batteryDetailsFromTB };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const cronOdkBatteryAPI = createAsyncThunk(
  "batteries/cronOdkBatteryAPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/odk-battery-operations`,
        {
          params,
        },
      );

      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBatterySwapsById = createAsyncThunk(
  "batteries/getBatterySwapsById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/swaps`,
        {
          params,
        },
      );
      // console.log(response.data, 'Battery Swaps Data');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getBatteryPacks = createAsyncThunk(
  "batteries/getBatteryPacks",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/battery-pack`,
        {
          params,
        },
      );
      // console.log(response.data, 'Battery Pack Data');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getBatteriesFromTrikes = createAsyncThunk(
  "batteries/getBatteriesFromTrikes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/batteries`,
        {
          params,
        },
      );
      // console.log(response.data, 'Battery Pack Data');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// phase3

export const getBatteryV3 = createAsyncThunk(
  "batteries/getBatteryV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/get-battery`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addBatteryV3 = createAsyncThunk(
  "batteries/addBatteryV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/battery/add-battery`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editBatteryV3 = createAsyncThunk(
  "batteries/editBatteryV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/battery/edit-battery`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBatteryV3 = createAsyncThunk(
  "batteries/deleteBatteryV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/battery/delete-battery`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getKPI = createAsyncThunk(
  "batteries/getKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/kpi-data`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllBatteriesV3 = createAsyncThunk(
  "batteries/getAllBatteriesV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/battery/get-all-batteries`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const retireBattery = createAsyncThunk(
  "batteries/retireBattery",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/battery/retire-battery`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const unRetireBattery = createAsyncThunk(
  "batteries/unRetireBattery",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/battery/unretire-battery`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const createSession = createAsyncThunk(
  "sessions/createSession",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/session/create-session`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSessions = createAsyncThunk(
  "sessions/getSessions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/session/get-sessions`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSessionById = createAsyncThunk(
  "sessions/getSessionById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/session/session-details`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSessionKPI = createAsyncThunk(
  "sessions/getSessionKP",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/session/get-session-kpi`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editSession = createAsyncThunk(
  "sessions/editSession",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/session/edit-session`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSession = createAsyncThunk(
  "sessions/deleteSession",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/session/delete-session`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import ClockIcon from "../../../Common/Svgs/ClockIcon";
import LocationTickIcon from "../../../Common/Svgs/LocationTickIcon";
import getTimeDifference, {
  tripDuration,
} from "../../../Utils/getTimeDifference";
import TripStartTimeIcon from "../../../Common/Svgs/TripStartTimeIcon";
import TripEndTimeIcon from "../../../Common/Svgs/TripEndTimeIcon";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import WeightCollectedIcon from "../../../Common/Svgs/WeightCollectedIcon";
import WeightDeliveredIcon from "../../../Common/Svgs/WeightDeliveredIcon";
import { TRIP_STATUS } from "./TripConstants";
import { getBatteryText, getWeightText } from "./formatValues";

export const KPIData = (currentTrip) => {
  return [
    {
      id: 1,
      icon: <ClockIcon className="text-kpi-background" />,
      title: "Total trip time",
      // value: optimisedRouteTime,
      value:
        getTimeDifference(
          dayjs(currentTrip?.startTime).format("hh:mm"),
          dayjs(currentTrip?.endTime).format("hh:mm"),
        ) || "--",
    },
    {
      id: 2,
      icon: <LocationTickIcon className="text-kpi-background" />,
      title: "No. Of Stops",
      value: currentTrip?.stops?.length,
    },
    {
      id: 3,
      icon: <TripStartTimeIcon />,
      title: "Start Time",
      value:
        currentTrip.startTime !== null
          ? dayjs(currentTrip.startTime).format("hh:mm A")
          : currentTrip?.timeSlot,
    },
    {
      id: 4,
      icon: <TripEndTimeIcon />,
      title: "End Time",
      value:
        currentTrip.endTime !== null
          ? dayjs(currentTrip.endTime).format("hh:mm A")
          : "--",
    },
  ];
};

export const CompletedTripData = (overallSummary) => {
  return [
    {
      id: 1,
      icon: <LocationTickIcon className="text-kpi-background" />,
      title: "No. Of Stops",
      value: overallSummary?.stopsId?.length || "--",
    },
    {
      id: 2,
      icon: <TripsIcon size={16} className="text-kpi-background" />,
      title: "Total Distance",
      value: parseFloat(overallSummary?.distance)?.toFixed(2) || "--",
      unit: "Km",
    },
    {
      id: 3,
      icon: <WeightCollectedIcon className="text-kpi-background" />,
      title: "Total Weight Collected",
      value:
        parseFloat(overallSummary?.totalWeightCollected)?.toFixed(2) || "--",
      unit: "Kg",
    },
    {
      id: 4,
      icon: <WeightDeliveredIcon className="text-kpi-background" />,
      title: "Total Weight Delivered",
      value:
        parseFloat(overallSummary?.totalWeightDelivered)?.toFixed(2) || "--",
      unit: "Kg",
    },
  ];
};

export const UpcomingAndOngoingData = (tripDetailsKPI, currentTrip) => {
  return [
    {
      id: 1,
      icon: <LocationTickIcon className="text-kpi-background" />,
      title: "No. Of Stops",
      value: tripDetailsKPI?.stops || "--",
    },
    {
      id: 2,
      icon: <TripsIcon size={16} className="text-kpi-background" />,
      title: "Total Distance",
      value: tripDetailsKPI?.distance || "--",
      unit: "Km",
    },
    {
      id: 3,
      icon: <WeightCollectedIcon className="text-kpi-background" />,
      title: "Total Weight Collected",
      // value:
      //   (currentTrip.statusId === TRIP_STATUS.ONGOING &&
      //     tripDetailsKPI?.totalCollected) ||
      //   "--",
      value: getWeightText(tripDetailsKPI.totalCollected, currentTrip.statusId),
      unit:
        currentTrip.statusId === TRIP_STATUS.ONGOING &&
        tripDetailsKPI?.totalCollected
          ? "Kg"
          : null,
    },
    {
      id: 4,
      icon: <WeightDeliveredIcon className="text-kpi-background" />,
      title: "Total Weight Delivered",
      // value:
      //   (currentTrip.statusId === TRIP_STATUS.ONGOING &&
      //     tripDetailsKPI?.totalDelivered) ||
      //   "--",
      value: getWeightText(tripDetailsKPI.totalDelivered, currentTrip.statusId),
      unit:
        currentTrip.statusId === TRIP_STATUS.ONGOING &&
        tripDetailsKPI?.totalDelivered
          ? "Kg"
          : null,
    },
  ];
};

export const tripData = (
  currentTrip,
  currentTrike,
  checklistPassed,
  checklistTotal,
) => {
  const dispatch = useDispatch();

  return [
    {
      id: 1,
      label: "Driver name",
      value: currentTrip?.driverName,
      redirect: false,
      // onClick: () => {
      //   if (hasDriverPermission) {
      //     navigate("/users");
      //   }
      // },
    },
    {
      id: 2,
      label: "Trike no.",
      value: currentTrip?.trikeRegistrationNo || "--",
      redirect: false,
      // onClick: () => {
      //   if (hasTrikePermission && currentTrip.trikeRegistrationNo !== "") {
      //     navigate(`/trikes/${currentTrip?.trikeRegistrationNo}`);
      //   }
      // },
    },
    {
      id: 3,
      label: "Date of trip",
      value: dayjs(currentTrip?.startDate).format("MMM DD YYYY, hh:mm a"),
    },
    {
      id: 4,
      label: "Total trip duration",
      value:
        // tripDuration(
        //   dayjs(currentTrip?.startTime),
        //   dayjs(currentTrip?.endTime),
        // ) ||
        currentTrip?.time || "--",
    },
    {
      id: 5,
      label: "No. of batteries assigned",
      value: currentTrip?.batteries
        ? getBatteryText(currentTrip.batteries)
        : "--",
      redirect: false,
      // onClick: () => {
      //   if (hasBatteryPermission) {
      //     navigate(`/batteries`);
      //   }
      // },
    },
    {
      id: 6,
      label: "Odometer start-end",
      value: `${currentTrike?.odometerStart || "--"} ${
        currentTrike?.odometerEnd || "--"
      }`,
    },
    {
      id: 7,
      label: "Payload",
      value:
        currentTrip.trikeId !== ""
          ? `${currentTrike?.currentPayload} Kg`
          : "--",
    },
    {
      id: 8,
      label: "Safety Checklist",
      value:
        currentTrip.statusId === TRIP_STATUS.ONGOING &&
        currentTrip.trikeId !== "" ? (
          <div>
            {checklistPassed}/{checklistTotal}
          </div>
        ) : (
          "--"
        ),
      redirect: currentTrip.statusId === TRIP_STATUS.ONGOING,
      onClick: () => {
        dispatch(
          updateTrikes({
            key: "checklistModal",
            value: {
              visible: true,
              trike: currentTrike,
              checklist: [],
            },
          }),
        );
      },
    },
  ];
};

export const CompletedTripInfoCard = (
  overallSummary,
  currentTrike,
  checklistPassed,
  checklistTotal,
) => {
  const dispatch = useDispatch();

  return [
    {
      id: 1,
      label: "Driver name",
      value: overallSummary?.driverName,
      redirect: false,
      // onClick: () => {
      //   if (hasDriverPermission) {
      //     navigate("/users");
      //   }
      // },
    },
    {
      id: 2,
      label: "Trike no.",
      value: overallSummary?.trikeRegistrationNo || "--",
      redirect: false,
      // onClick: () => {
      //   if (
      //     hasTrikePermission &&
      //     (overallSummary?.trikeRegistrationNo !== "" ||
      //       overallSummary?.trikeRegistrationNo !== 0)
      //   ) {
      //     navigate(`/trikes/${overallSummary?.trikeRegistrationNo}`);
      //   }
      // },
    },
    {
      id: 3,
      label: "Date of trip",
      value: dayjs(overallSummary?.startTime).format("MMM DD YYYY, hh:mm a"),
    },
    {
      id: 4,
      label: "Total Trip Duration",
      value: tripDuration(overallSummary?.startTime, overallSummary?.endTime),
      // value:
      //   getTimeDifference(
      //     dayjs(overallSummary?.startTime).format("hh:mm"),
      //     dayjs(overallSummary?.endTime).format("hh:mm"),
      //   ) || "--",
    },
    {
      id: 5,
      label: "No. of batteries assigned",
      value: overallSummary?.batteries
        ? `${overallSummary.batteries.length} Batteries`
        : "--",
      redirect: false,
      // onClick: () => {
      //   if (hasBatteryPermission) {
      //     navigate(`/batteries`);
      //   }
      // },
    },
    {
      id: 6,
      label: "Odometer start-end",
      value: `${currentTrike?.odometerStart || "--"} ${
        currentTrike?.odometerEnd || "--"
      }`,
    },
    {
      id: 7,
      label: "Payload",
      value:
        overallSummary.trikeId !== ""
          ? `${currentTrike?.currentPayload} Kg`
          : "--",
    },
    {
      id: 8,
      label: "Safety Checklist",
      value:
        overallSummary.trikeId !== "" ? (
          <div>
            {checklistPassed}/{checklistTotal}
          </div>
        ) : (
          "--"
        ),
      redirect: true,
      onClick: () => {
        dispatch(
          updateTrikes({
            key: "checklistModal",
            value: {
              visible: true,
              checklist: currentTrike.checklist,
              trike: currentTrike,
            },
          }),
        );
      },
    },
  ];
};

export const listForTrikeChange = [
  {
    id: 1,
    reason: "Battery is discharged",
  },
  {
    id: 2,
    reason: "Tire is punctured",
  },
  {
    id: 3,
    reason: "Trike engine is not starting",
  },
  {
    id: 4,
    reason: "Break fail",
  },
  {
    id: 5,
    reason: "Met with an accident",
  },
];

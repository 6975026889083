/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import { TickCircleIcon } from "../../Common/Svgs/TickIcon";
import AlertIcon from "../../Common/Svgs/AlertIcon";

const ToastMessageModal = ({
  visible,
  onCancel,
  title,
  description,
  isError = false,
}) => {
  if (!visible) {
    return <></>;
  }

  const getIcon = () => {
    if (isError) {
      return (
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-alert-red">
          <AlertIcon size={24} className="text-white" />
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-10">
        <TickCircleIcon />
      </div>
    );
  };

  return (
    <div className="fixed bottom-12 right-4 h-[150px] p-4 rounded-xl w-[536px] bg-background-white shadow-toastMessage">
      <div className="flex items-center justify-between">
        {getIcon()}

        <CloseIcon
          onClick={onCancel}
          className="w-4 h-4 cursor-pointer stroke-dark-gray"
        />
      </div>
      <div className="flex flex-col px-2 mt-2 gap-y-2">
        <p className="text-base font-medium text-black capitalize font-poppins">
          {title}
        </p>
        <p className="text-xs font-light text-dark-gray font-poppins">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ToastMessageModal;

/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { batteryPercentageFilters, statusFilters } from "../Data";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import MoreBatteryFilters from "./MoreBatteryFilters";
import { client } from "../../../Utils/axiosClient";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const BatteryListHeader = ({
  showKpi,
  setShowKpi,
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const { allHubs } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allHubs.map((hub) => ({
    id: hub?.id,
    value: hub?.name,
  }));

  const hasAddPermission = usePermission([PERMISSIONS.BATTERY_ADD]);
  const hasRequestPermission = usePermission([PERMISSIONS.BATTERY_REQUEST]);

  const [drivers, setDrivers] = useState({
    result: [],
    totalRecords: 0,
  });
  const [focus, setFocus] = useState(false);

  const driverFilters = drivers.result
    .map((item) => {
      return {
        id: item.id,
        value: `${item.firstName} ${item.lastName}`,
      };
    })
    .sort((a, b) => a?.value?.localeCompare(b?.value));

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const getDrivers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/drivers/get-drivers`,
        {
          params: {
            limit: 100,
          },
        },
      );

      setDrivers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDrivers();
  }, [getDrivers]);

  return (
    <div className="bg-background-white px-6 py-2.5 border-t-[0.5px] border-light-grey flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search Batteries"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />

        <FilterItem
          label="Hub Station"
          identifier="hub"
          contentData={hubFilters}
          onSelect={onSelect}
          selectedValues={filters.hub}
          className="max-h-[200px] overflow-y-scroll"
          enableSearch
        />

        <FilterItem
          label="Battery %"
          identifier="battery"
          className="max-h-[200px] overflow-y-scroll"
          contentData={batteryPercentageFilters}
          onSelect={onSelect}
          selectedValues={filters.battery}
        />

        <FilterItem
          label="Driver"
          identifier="driver"
          contentData={driverFilters}
          onSelect={onSelect}
          selectedValues={filters.driver}
          className="max-h-[200px] overflow-y-scroll"
        />

        <FilterItem
          label="Status"
          identifier="status"
          className="max-h-[200px] overflow-y-scroll"
          customValue
          contentData={statusFilters}
          onSelect={onSelect}
          selectedValues={filters.status}
        />

        <div className="bg-light-grey w-[1px] h-5 mx-1" />

        <MoreBatteryFilters filters={filters} setFilters={setFilters} />
      </div>

      <div className="flex items-center gap-x-3">
        {hasRequestPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-yellow hover:bg-yellow-dark rounded-md"
            onClick={() => navigate("/request-batteries")}
          >
            <div className="flex item-center gap-x-2">
              <img
                src="/assets/icons/request-battery.svg"
                className="w-4 h-4"
                alt=""
              />

              <p className="font-poppins text-xs leading-4.5 font-medium text-black">
                Request Batteries
              </p>
            </div>
          </button>
        )}

        {hasAddPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/add-battery")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Add Battery
              </p>
            </div>
          </button>
        )}

        <button
          type="button"
          className="flex items-center justify-center w-8 h-6 rounded-md bg-[#eeeeee]"
          onClick={() => setShowKpi(!showKpi)}
        >
          <ChevronIcon
            className={`w-4 h-4 stroke-black ${
              showKpi ? "rotate-90" : "-rotate-90"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default BatteryListHeader;

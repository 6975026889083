/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { client } from "../../../Utils/axiosClient";
import { TILLER_STATUS } from "../../Tiller/constants";

export const useFarmersOnAdd = () => {
  const [farmers, setFarmers] = useState([]);

  const getFarmers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/filter-data`,
      );

      const temp = response.data.farmers.map((item) => ({
        id: item.id,
        value: item.name,
      }));

      setFarmers([...temp]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getFarmers();
  }, [getFarmers]);

  return farmers;
};

export const useFarmsForFarmer = (farmerId) => {
  const { id } = useParams();

  const { currentSession } = useSelector((state) => state.sessions);

  const [farmsArr, setFarmsArr] = useState([]);

  const getFarms = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/get-farms`,
        {
          params: {
            farmerId: +farmerId,
          },
        },
      );

      // while editing a session, the selected farm co-ordinates will always appear first
      if (id) {
        const selected = response.data.farms.find(
          (item) => item.id == currentSession?.farmerLocationId,
        );

        const temp = response.data.farms
          .filter((f) => f.id != currentSession?.farmerLocationId)
          .map((item, index) => ({
            id: item?.id,
            value: `Farm ${index + 2} (${item?.lat}, ${item?.long})`,
          }));

        setFarmsArr([
          {
            id: selected?.id,
            value: `Farm 1 (${selected?.lat}, ${selected?.long})`,
          },
          ...temp,
        ]);
      } else {
        const temp = response.data.farms.map((item, index) => ({
          id: item?.id,
          value: `Farm ${index + 1} (${item?.lat}, ${item?.long})`,
        }));

        setFarmsArr([...temp]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [farmerId, currentSession, id]);

  useEffect(() => {
    if (farmerId) getFarms();
  }, [getFarms, farmerId]);

  return farmsArr;
};

// include all the drivers
// filtering based on selected hub id
export const useAgentsOnAdd = (watch) => {
  const { allDrivers } = useSelector((state) => state.users);

  if (!allDrivers.length) return [];

  let agents = allDrivers;
  const localHubIdState = watch("hubId");

  if (localHubIdState) {
    agents = agents.filter((agent) => agent.hubId == localHubIdState);
  }

  return agents.map((item) => ({
    id: item.id,
    value: `${item.firstName} ${item.lastName}`,
  }));
};

// filtering based on selected hub id
export const useTillersOnAdd = (watch) => {
  const { allTillers } = useSelector((state) => state.tillers);

  if (!allTillers.length) return [];

  let tillers = allTillers.filter(
    (tiller) => +tiller.status === TILLER_STATUS.HUB_STATION,
  );

  const localHubIdState = watch("hubId");

  if (localHubIdState) {
    tillers = tillers.filter((tiller) => +tiller.hubId === localHubIdState);
  }

  return tillers.map((item) => ({
    id: item.id,
    value: item.registrationNo,
  }));
};

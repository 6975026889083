import React from "react";
import EditIcon from "../../Common/Svgs/EditIcon";
import QRCodeIcon from "../../Common/Svgs/QRCodeIcon";
import TrashIcon from "../../Common/Svgs/TrashIcon";
import AddToServiceIcon from "../../Common/Svgs/AddToServiceIcon";
import AddSquareIcon from "../../Common/Svgs/AddSquareIcon";
import MoreSquareIcon from "../../Common/Svgs/MoreSquareIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";

const useTillerThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TILLER_EDIT]),
    },
    {
      id: 2,
      value: "Add Issues",
      icon: <AddSquareIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TILLER_ISSUE_ADD]),
    },
    {
      id: 3,
      value: "View Issues",
      icon: (
        <MoreSquareIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TILLER_ISSUE_VIEW]),
    },
    {
      id: 4,
      value: "Add to Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TILLER_ADD_TO_SERVICE]),
    },
    {
      id: 5,
      value: "Check Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([
        PERMISSIONS.SERVICE_VIEW,
        PERMISSIONS.SERVICE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 6,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TILLER_VIEW_QR]),
    },
    {
      id: 7,
      value: "Delete Tiller",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.TILLER_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useTillerThreeDotsOptions;

import React from "react";

const TillerIcon = ({ size = 16, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.001 10.776c-.736 0-1.333.573-1.333 1.279s.597 1.278 1.333 1.278c.737 0 1.334-.572 1.334-1.278S5.738 10.776 5 10.776m0 0V8"
        />
        <path fill="currentColor" d="M4.375 6 5 8h10.333a1 1 0 1 0 0-2z" />
        <path
          fill="currentColor"
          d="M3 7a1 1 0 0 0 1 1h1l-.625-2H4a1 1 0 0 0-1 1"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M1 2.667h.863A2 2 0 0 1 3.772 4.07L4.375 6M5 8h10.333a1 1 0 0 0 1-1v0a1 1 0 0 0-1-1H4.375M5 8H4a1 1 0 0 1-1-1v0a1 1 0 0 1 1-1h.375M5 8l-.625-2"
        />
        <path
          fill="currentColor"
          d="M12.335 14c-.327-1.307-1.347-1.4-2-1.4h-.667V8h1.333v2.582c.79.473 1.939 1.603 1.334 3.418M16.335 14c-.327-1.307-1.347-1.4-2-1.4h-.667V8h1.333v2.582c.79.473 1.939 1.603 1.334 3.418"
        />
      </svg>
    </span>
  );
};

export default TillerIcon;

import React from "react";

const OperationsIcon = ({ size, className = "", fillColor }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.501 1.667V10c0 .917-.75 1.667-1.666 1.667H1.668V5a3.33 3.33 0 0 1 3.333-3.333z"
          fill={fillColor}
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M18.335 11.667v2.5c0 1.383-1.117 2.5-2.5 2.5H15c0-.917-.75-1.667-1.666-1.667-.917 0-1.667.75-1.667 1.667H8.335c0-.917-.75-1.667-1.667-1.667s-1.667.75-1.667 1.667h-.833a2.497 2.497 0 0 1-2.5-2.5v-2.5h9.167c.916 0 1.666-.75 1.666-1.667V4.167h1.534c.6 0 1.15.325 1.45.841L16.91 7.5h-1.075a.836.836 0 0 0-.834.833v2.5c0 .459.375.834.834.834z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.667 18.333a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333M13.335 18.333a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333M18.333 10v1.667h-2.5a.836.836 0 0 1-.833-.834v-2.5c0-.458.375-.833.833-.833h1.075z"
        />
      </svg>
    </span>
  );
};

export default OperationsIcon;

import React from "react";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import EditIcon from "../../../Common/Svgs/EditIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";

const useBatteryThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "View Details",
      icon: (
        <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([
        PERMISSIONS.BATTERY_VIEW,
        PERMISSIONS.BATTERY_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 2,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.BATTERY_EDIT]),
    },
    {
      id: 3,
      value: "Add to Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.BATTERY_ADD_TO_SERVICE]),
    },
    {
      id: 4,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.BATTERY_VIEW_QR]),
    },
    {
      id: 5,
      value: "Retire Battery",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: false,
    },
    {
      id: 6,
      value: "Activate Battery",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: false,
    },
    {
      id: 7,
      value: "Delete Battery",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.BATTERY_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useBatteryThreeDotsOptions;

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../Core/Components/CustomButton";
import useHeaderItems from "./useHeaderItems";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import CustomTooltip from "../../Core/Components/CustomTooltip";

const Buttons = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");

  const hasTrikeAddPermission = usePermission([PERMISSIONS.TRIKE_ADD]);
  const hasTillerAddPermission = usePermission([PERMISSIONS.TILLER_ADD]);

  // checking permissions for trike checklist (add, edit and delete)
  const hasTrikeChecklistPermission = usePermission([
    PERMISSIONS.TRIKE_CHECKLIST_ADD,
    PERMISSIONS.TRIKE_CHECKLIST_EDIT,
    PERMISSIONS.TRIKE_CHECKLIST_DELETE,
  ]);

  // checking permissions for trike checklist (add, edit and delete)
  const hasTillerChecklistPermission = usePermission([
    PERMISSIONS.TILLER_CHECKLIST_ADD,
    PERMISSIONS.TILLER_CHECKLIST_EDIT,
    PERMISSIONS.TILLER_CHECKLIST_DELETE,
  ]);

  if (selectedTab === "trikes") {
    return (
      <div className="flex items-center gap-x-3">
        {hasTrikeChecklistPermission && (
          <CustomTooltip tooltipTitle="Checklist">
            <button
              type="button"
              className="bg-yellow hover:bg-yellow-dark p-3 w-10 h-10 rounded-md"
              onClick={() => navigate("/create-checklist/trike")}
            >
              <img
                src="/assets/icons/checklist.svg"
                className="w-4 h-4"
                alt="Checklist"
              />
            </button>
          </CustomTooltip>
        )}

        {hasTrikeAddPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/assets/add-trike")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Add Trike
              </p>
            </div>
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-3">
      {hasTillerChecklistPermission && (
        <CustomTooltip tooltipTitle="Checklist">
          <button
            type="button"
            className="bg-yellow hover:bg-yellow-dark p-3 w-10 h-10 rounded-md"
            onClick={() => navigate("/create-checklist/tiller")}
          >
            <img
              src="/assets/icons/checklist.svg"
              className="w-4 h-4"
              alt="Checklist"
            />
          </button>
        </CustomTooltip>
      )}

      {hasTillerAddPermission && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={() => navigate("/assets/add-tiller")}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Tiller
            </p>
          </div>
        </button>
      )}
    </div>
  );
};

const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const headerButtons = useHeaderItems().filter((item) => item.hasPermission);

  const selectedTab = searchParams.get("tab");

  const onTabClick = (item) => {
    searchParams.set("tab", item.text.toLowerCase());
    setSearchParams(searchParams);
  };

  return (
    <div className="px-6 py-3.5 flex items-center justify-between border-b bg-background-white">
      <div className="flex items-center gap-x-6">
        {headerButtons.map((item) => {
          const selected = selectedTab === item.text.toLowerCase();

          return (
            <Button
              key={item.id}
              colors={selected ? "primary" : "white"}
              width={item.width}
              padding={item.padding}
              modifier="shadow"
              onClick={() => onTabClick(item)}
            >
              <div>{item.text}</div>
            </Button>
          );
        })}
      </div>

      <Buttons />
    </div>
  );
};

export default Header;

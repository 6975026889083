import React from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { SESSION_STATUS } from "../constants";
import { updateSession } from "../../../Redux/Slices/sessions.slice";

export const useSessionCardData = () => {
  const { currentSession } = useSelector((state) => state.sessions);

  const getEstimatedCost = () => {
    const total =
      parseFloat(currentSession?.estimatedCostPerSqm) *
      parseFloat(currentSession?.estimatedArea);

    return total.toFixed(2);
  };

  return [
    {
      title: "Activity Type",
      value: "Tilling",
    },
    {
      title: "Date",
      value: dayjs(currentSession?.activityDate).format("MMM DD, YYYY") || "--",
    },
    {
      title: "Start Time",
      value: <p className="uppercase">{currentSession?.timeSlot || "--"}</p>,
    },
    {
      title: "Estimated Area",
      value: `${currentSession?.estimatedArea || "--"} Sq.M`,
    },
    {
      title: "Estimated Cost/Sq.M",
      value: `${currentSession?.estimatedCostPerSqm} Ksh`,
    },
    {
      title: "Total Estimated Cost",
      value: `${getEstimatedCost()} Ksh`,
    },
    {
      title: "Farmer Name",
      value: currentSession?.farmerFirstName
        ? `${currentSession?.farmerFirstName} ${currentSession?.farmerLastName}`
        : "--",
    },
    {
      title: "Location",
      value:
        currentSession?.farmLat && currentSession?.farmLong
          ? `${currentSession.farmLat}, ${currentSession.farmLong}`
          : "--",
    },
    {
      title: "Hub Station",
      value: <p className="capitalize">{currentSession?.hubName}</p>,
    },
  ];
};

export const useAssetCardData = () => {
  const dispatch = useDispatch();

  const { currentSession } = useSelector((state) => state.sessions);

  const { tillerChecklist } = useSelector((state) => state.tillers);

  const data = [
    {
      title: "Type",
      value: "Tiller",
    },
    {
      title: "ID",
      value: currentSession?.assetRegistrationNo || "--",
    },
    {
      title: "Safety Checklist",
      value:
        currentSession.status === SESSION_STATUS.UPCOMING
          ? "--"
          : `${tillerChecklist.length}/${tillerChecklist.length}`,
      onClick:
        currentSession.status === SESSION_STATUS.UPCOMING
          ? null
          : () =>
              dispatch(updateSession({ key: "checklistModal", value: true })),
    },
  ];

  if (!currentSession.batteries.length) {
    data.push({
      title: "Batteries",
      value: "Not Assigned",
    });

    return data;
  }

  currentSession.batteries.forEach((item, index) => {
    data.push({
      title: `Battery ${index + 1}`,
      value: item.registrationNo,
    });
  });

  return data;
};

export const useAgentCardData = () => {
  const { currentSession } = useSelector((state) => state.sessions);

  return [
    {
      title: "Name",
      value: currentSession?.agentFirstName
        ? `${currentSession?.agentFirstName} ${currentSession?.agentLastName}`
        : "--",
    },
    {
      title: "Role",
      value: (
        <p className="capitalize">{currentSession?.agentRoleName || "--"}</p>
      ),
    },
  ];
};

export const useSessionSummaryData = () => {
  const { currentSession } = useSelector((state) => state.sessions);

  if (currentSession.status !== SESSION_STATUS.COMPLETED) {
    return [];
  }

  const getTotalCost = () => {
    const total =
      parseFloat(currentSession?.actualArea) *
      parseFloat(currentSession?.actualCostPerSqm);

    return total.toFixed(2);
  };

  const getActualAreaTrend = () => {
    // actual area was more
    if (
      parseFloat(currentSession?.actualArea) >
      parseFloat(currentSession?.estimatedArea)
    )
      return "up";

    // actual area was less
    if (
      parseFloat(currentSession?.actualArea) <
      parseFloat(currentSession?.estimatedArea)
    )
      return "down";

    // equal area
    return false;
  };

  const getActualCostTrend = () => {
    // actual cost was more
    if (
      parseFloat(currentSession?.actualTotalCost) >
      parseFloat(currentSession?.estimatedTotalCost)
    )
      return "up";

    // actual cost was less
    if (
      parseFloat(currentSession?.actualTotalCost) <
      parseFloat(currentSession?.estimatedTotalCost)
    )
      return "down";

    // equal cost
    return false;
  };

  return [
    {
      title: "Batteries Used",
      value: currentSession?.batteries?.length || "--",
    },
    {
      title: "Duration",
      value: `${dayjs(currentSession?.startTime).format("hh:mm A")} - ${dayjs(
        currentSession?.endTime,
      ).format("hh:mm A")}`,
    },
    {
      title: "Location",
      value: `${currentSession?.farmLat}, ${currentSession?.farmLong}`,
    },
    {
      title: "Total Actual Area",
      value: `${currentSession?.actualArea} Sq.M`,
      trend: getActualAreaTrend(),
    },
    {
      title: "Total Actual Cost",
      value: `${getTotalCost()} Ksh`,
      trend: getActualCostTrend(),
    },
    {
      title: "MPESA Reference",
      value: currentSession?.mpesa || "--",
    },
  ];
};

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CustomLayout from "../../Core/Layout/CustomLayout";
import TrikesList from "../Trikes/phase3/TrikesList";
import Header from "./Header";
import TillerList from "../Tiller/TillerList";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import UnauthorizedAccess from "../../Core/Components/UnauthorizedAccess";

const AssetList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab");

  const hasTrikeModulePermission = usePermission([
    PERMISSIONS.TRIKE_VIEW,
    PERMISSIONS.TRIKE_VIEW_AND_EDIT,
  ]);

  const hasTillerModulePermission = usePermission([
    PERMISSIONS.TILLER_VIEW,
    PERMISSIONS.TILLER_VIEW_AND_EDIT,
  ]);

  useEffect(() => {
    if (!searchParams.has("tab")) {
      searchParams.set("tab", "trikes");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  // if module permission does not exist
  if (selectedTab === "trikes" && !hasTrikeModulePermission)
    return <UnauthorizedAccess />;

  if (selectedTab === "tillers" && !hasTillerModulePermission)
    return <UnauthorizedAccess />;

  return (
    <CustomLayout pageName="Assets">
      <Header />
      {selectedTab === "trikes" ? <TrikesList /> : <TillerList />}
    </CustomLayout>
  );
};

export default AssetList;

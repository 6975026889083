import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const addTiller = createAsyncThunk(
  "tiller/addTiller",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/add-tiller`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTillers = createAsyncThunk(
  "tiller/getTillers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiller/get-tillers`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTillerKPI = createAsyncThunk(
  "tiller/getTillerKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiller/kpi`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editTiller = createAsyncThunk(
  "tiller/editTiller",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/tiller/edit-tiller`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteTiller = createAsyncThunk(
  "tiller/deleteTiller",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/delete-tiller`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllTillers = createAsyncThunk(
  "tiller/getAllTillers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiller/get-all-tillers`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTillerById = createAsyncThunk(
  "tiller/getTillerById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiller/get-tiller-by-id`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTillerChecklist = createAsyncThunk(
  "tiller/getTillerChecklist",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tiller/get-tiller-checklist`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createTillerChecklist = createAsyncThunk(
  "tiller/createTillerChecklist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/create-tiller-checklist`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTillerChecklist = createAsyncThunk(
  "tiller/updateTillerChecklist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/update-tiller-checklist`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addKnownIssues = createAsyncThunk(
  "tiller/addKnownIssues",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/add-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateKnownIssues = createAsyncThunk(
  "tiller/updateKnownIssues",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/update-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resolveKnownIssue = createAsyncThunk(
  "tiller/resolveKnownIssue",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tiller/resolve-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

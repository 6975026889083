import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import DuplicateIcon from "../../../Common/Svgs/DuplicateIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS, SEED_ROLES } from "../../Login/constants";

const seedRoles = [
  SEED_ROLES.SUPER_ADMIN,
  SEED_ROLES.HUB_MANAGER,
  SEED_ROLES.DRIVER,
];

const useRoleThreeDots = (role) => {
  const isSeedUser = seedRoles.includes(+role.id);

  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SETTINGS_ROLE_EDIT]),
    },
    {
      id: 2,
      value: "Duplicate",
      icon: <DuplicateIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SETTINGS_ROLE_CREATE]),
    },
    {
      id: 3,
      value: "Delete Role",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission:
        usePermission([PERMISSIONS.SETTINGS_ROLE_DELETE]) && !isSeedUser,
    },
  ];

  return threeDotsOptions;
};

export default useRoleThreeDots;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import useTillerThreeDotsOptions from "./useTillerThreeDotsOptions";
import { updateTiller } from "../../Redux/Slices/tillers.slice";
import { TILLER_STATUS } from "./constants";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import { QR_CODE_TYPE } from "../QRCode/data";

const ListDropdown = ({ detail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.tillers);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useTillerThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const getOptions = () => {
    let options = threeDotsOptions;

    // if in service, do not show add to service option
    if (detail.status === TILLER_STATUS.SERVICE) {
      options = options.filter((item) => item.id !== 4);
    }

    // if not in service, do not show check service option
    if (detail.status !== TILLER_STATUS.SERVICE) {
      options = options.filter((item) => item.id !== 5);
    }

    if (detail.knownIssues.length)
      options = options.filter((item) => item.id !== 2);

    if (!detail.knownIssues.length)
      options = options.filter((item) => item.id !== 3);

    return options;
  };

  const onOptionClick = async (optionId) => {
    dispatch(
      updateTiller({
        key: "currentTiller",
        value: detail,
      }),
    );

    setOpen(false);

    switch (optionId) {
      case 1:
        navigate(`/assets/edit-tiller/${detail.id}`);
        break;
      case 2:
        navigate(`/assets/tiller-known-issue/${detail.registrationNo}`);
        break;
      case 3:
        navigate(`/assets/tiller-known-issue/${detail.registrationNo}`);
        break;
      case 4:
        navigate(`/add-to-service/tillers/${detail.registrationNo}`);
        break;
      case 5:
        navigate("/service");
        break;
      case 6:
        dispatch(
          updateQrCode({
            key: "viewModal",
            value: {
              visible: true,
              data: {
                assetId: detail.registrationNo,
                assetType: QR_CODE_TYPE.TILLER,
                metaData: {
                  tillerid: detail.id,
                },
              },
            },
          }),
        );
        break;
      case 7:
        dispatch(
          updateTiller({
            key: "deleteTillerModal",
            value: true,
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateTiller({
        key: "threeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!getOptions().length) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === detail.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {getOptions().map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group 
              ${
                option.id === 7
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  option.id === 7
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
